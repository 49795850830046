const translate = {
    common: {
        login: "Логин",
        password: "Пароль",
        maskedPassword: "**********",
        date: "Дата",
        today: "Сегодня",
        yesterday: "Вчера",
        currentWeek: "Текущая неделя",
        currentMonth: "Текущий месяц",
        currentYear: "Текущий год",
        period: "Период",
        tier: "Уровень",
        save: "Сохранить",
        operators: "Операторы",
        search: "Поиск",
        online: "Онлайн",
        pause: "Пауза",
        offline: "Офлайн",
        chats: "Чаты",
        chat: "Чат",
        channels: "Каналы",
        type: "Тип",
        token: "Токен",
        enabled: "Включен",
        name: "Название",
        settings: "Настройки",
        permissions: "Разрешения",
        contact: "Контакт",
        channel: "Канал",
        accounts: "Аккаунты",
        cancel: "Отменить",
        delete: "Удалить",
        restore: "Восстановить",
        merge: "Объеденить",
        detach: "Отделить",
        contacts: "Контакты",
        messengers: "Мессенджеры",
        created: "Создан",
        status: "Статус",
        mailing: "Рассылки",
        start: "Начать",
        message: "Сообщение",
        analytics: "Аналитика",
        synonyms: "Синонимы",
        export: "Экспорт",
        import: "Импорт",
        publish: "Опубликовать",
        generateQuestions: "Сген. вопросы",
        generateQuestionsConfirmation: "Генерация вопросов может привести к потере данных. Вы уверены?",
        answer: "Ответ",
        active: "Активные",
        inactive: "Неактивные",
        pending: "В ожидании",
        apply: "Применить",
        setting: "Настройка",
        editAsJson: "Редактировать как json",
        editAsTable: "Редактировать как таблицу",
        image: "Изображение",
        file: "Файл",
        send: "Отправить",
        close: "Закрыть",
        reject: "Отклонить",
        confirm: "Подтвердить",
        setOn: "Установлен",
        logout: "Выйти",
        wrongPasswordOrLoginOrLicenseMessage: "Ошибка авторизации. Проверьте логин и пароль.",
        id: "Ид",
        channelType: "Тип канала",
        channelName: "Название канала",
        chatTag: "Тег",
        telegram: "Телеграм",
        phrase: "Фраза",
        parameters: "Параметры",
        fileSendLikeImageError: "Данный тип изборажения можно отправить только как файл"
    },
    analytics: {
        customerJourney: "Путь клиента",
        statistics: "Статистика",
        monitoring: "Мониторинг",
        reports: "Отчеты",
        exportReport: "Экспорт",
        comeFrom: "Предыдущий шаг",
        dialog: "Диалог",
        wentTo: "Следующий шаг",
        getSettingForChartsMessage: "Не удается загрузить настройки для диаграмм.",
        reportOutOfRangeException: "Невозможно сформировать отчет из-за ограничений формата файла.",
        reportException: "Произошла ошибка при выгрузке отчета."
    },
    channels: {
        operatorsForChannel: "Операторы для канала",
        operator: "Оператор",
        feedback: "Фидбэк",
        vip: "ВИП",
        spam: "Спам",
        emptyNameTooltipErrorMessage: "Имя не может быть пустым",
        invalidFieldsMessage: "Некоторые поля недействительны",
        channelCreatingErrorMessage: "Пожалуйста, сохраните или удалите новый канал",
        createChannel: "Создать канал",
        sureDeleteChannel: "Вы действительно хотите удалить канал?",
        saveChannelSuccessMessage: "Сохранено успешно",
        saveNewChannelSuccessMessage: "Канал создан успешно",
        saveChannelErrorMessage: "Ошибка при сохранении канала",
        saveNewChannelErrorMessage: "Не сохраняется новый канал",
        channelAlreadyExists: "Канал с таким токеном уже существует",
        uniformWidgetStartSignalRError: "Произошла ошибка при подключении к коннектору {{url}}",
        deleteChannelSuccessMessage: "Удалено успешно",
        deleteNewChannelSuccessMessage: "Канал не сохранился",
        deleteChannelErrorMessage: "Ошибка при удаление канала",
        deleteNewChannelErrorMessage: "Канал с идентификатором {0} не может быть удален, так как в нем открыты чаты:",
        copyScriptHeader: "Скрипт скопирован",
        copyScriptBody: "Скрипт скопирован в буфер обмена. Используйте Ctrl+V, чтобы вставить его на свой сайт.",
        telegram: "Телеграм",
        viber: "Вайбер",
        vk: "Вк",
        widget: "Виджет",
        facebook: "Фейсбук",
        weChat: "ВиЧат",
        instagram: "Инстаграм",
        uniformWidget: "Виджет Униформы",
        ednaWhatsApp: "Вотсап (Edna)",
        whatsApp: "Вотсап",
        voiceChannel: "Голосовой канал",
        devinoWhatsApp: "Вотсап (Devino)",
        restartChannelSuccessMesage: "Канал успешно перезапущен",
        restartChannelQuestion: "Вы уверены, что хотите перезапустить канал?",
        restartOnlyEnabledChannel: "Канал должен быть включен"
    },
    chat: {
        transfer: "Перевод",
        transferToOperator: "Перевод на оператора",
        transferToBot: "Перевод на бота",
        transferToGroup: "Перевод на группу",
        invite: "Пригласить",
        editMessage: "Редактировать сообщение",
        doLeaveChat: "Вы хотите выйти из чата?",
        doExportChat: "Вы хотите экспортировать чат?",
        exportSuccess: "Чат успешно экспортирован",
        exportError: "Экспорт во внешнию CRM не настроен",
        externalCrmOptionsAreNotConfidured: "Настройки внешней crm не заданы",
        export: "Экспортировать",
        pause: "Пауза",
        unpause: "Снять паузу",
        setPauseHeader: "Выберите категорию паузы",
        removePauseHeader: "Снять паузу",
        removePause: "Снять паузу",
        resendMessage: "Переотправить сообщение",
        setPause: "Поставить на паузу",
        pauseWithoutNotifacation: "Пауза без уведомления клиента",
        pauseWithNotifacation: "Пауза с уведомлением клиента",
        pauseBotChatError: "Невозможно поставить чат с ботом на паузу",
        leave: "Покинуть",
        join: "Присоединиться",
        close: "Закрыть",
        closeChat: "Закрыть чат",
        save: "Сохранить",
        fillInfo: "Заполните информацию",
        category: 'Категория',
        subject: 'Тематика',
        categoryAndSubjectSavedError: 'Для закрытия чата заполните все поля',
        categoryAndSubjectSavedSuccessfully: 'Категория и тематика успешно сохраненыы',
        messageDeleted: "Message deleted",
        messageFileNoEdit: "Сообщение с файлом/изображением нельзя редактировать.",
        messageFileNoDelete: "Сообщение с файлом/изображением нельзя удалить.",
        cannotEditMessageWrongChannelType: "Невозможно отредактировать сообщение. Неправильный тип канала.",
        sendFileMessageError: "Произошла ошибка во время отправки файла",
        continue: "Продолжить",
        tagSaveError: "Для сохранения тегов заполните оба поля",
        tagSaveSuccess: "Теги успешно сохранены",
        markAsSpam: "Пометить как спам",
        contactHasOpenedChatException: "С этим контактом есть открытый чат. ChatId - {0} Operator - {1}",
        enabledChannelNotFoundException: "Невозможно начать новый чат, так как нет включенного канала",
        businessInitiatedTemplateNotFound: "Невозможно начать новый чат, так как в канале нет шаблона стартового сообщения.",
    },
    chats: {
        my: "Мои",
        withMe: "Со мной",
        all: "Все",
        archive: "Архив",
        autoUpdateChat: "Авто-обновление",
        info: "Информация",
        statistics: "Статистика",
        closed: "Закрыт",
        pause: "ПАУЗА",
        active: "Активен",
        expired: "Истек",
        finished: "Завершен",
        missed: "Пропущен",
        chatNumber: "Номер чата",
        chatTag: "Тэги чата",
        askYouWannaCloseChat: "Вы хотите закрыть чат?",
        askYouWannaJoinChat: "Вы хотите присоединиться к чату?",
        askYouWannaReopenChat: "Вы хотите переоткрыть чат?",
        chatOpenedSuccessMessage: "Чат успешно открыт.",
        chatNotOpenedMessageForSomeReason: "Чат не открывался. Возможно, с этим контактом уже есть открытые чаты.",
        chatNotOpenedMessage: "С этим контактом есть открытый чат. ChatId - {0} Operator - {1}",
        operatorNotAssignedYetMessage: "Еще не назначено",
        openChatErrorMessage: "Что-то пошло не так при открытии чата.",
        continueChatErrorMessage: "Не могу начать чат. Убедитесь, что канал существует и запущен.",
        editMessageSuccessMessage: "Отредактировано успешно",
        deleteMessageSuccessMessage: "Удалено успешно",
        editMessageErrorMessage: "Не могу отредактировать сообщение",
        chatAlreadyClosing: "Закрытие чата уже в обработке",
        chatClosedSuccessfully: "Чат успешно закрыт",
        chatClosedErrorMessage: "Произошла ошибка при закрытии чата",
        enterChatName: "Введите название чата...",
    },
    contact: {
        sureDeleteAccount: "Вы действительно хотите удалить аккаунт?",
        createNewAccounts: "Создать новый аккаунт",
        noRecords: "Записей нет",
        invalidFieldsErrorMessage: "Есть недопустимые поля",
        phoneNumber: "Номер телефона",
        firstName: "Имя",
        lastName: "Фамилия",
        middleName: "Отчество",
        chatName: "Название чата",
        comment: "Комментарий",
        chatsCount: "Количество чатов",
        locale: "Язык",
        contactCreatingErrorMessage: "Пожалуйста, сохраните или удалите несохраненный контакт",
        searchingInputPlaceholder: "поиск по имени, телефону или названию чата",
        noContacts: "Нет контактов",
        createContact: "Создать контакт",
        saveContactSuccessMessage: "Сохранено успешно",
        saveNewContactSuccessMessage: "Контакт создан успешно",
        saveContactErrorMessage: "Ошибка во время сохранения контакта",
        saveNewContactErrorMessage: "Не удается сохранить новый контакт",
        deleteContactSuccessMessage: "Удалено успешно",
        deleteNewContactSuccessMessage: "Удалено успешно",
        deleteContactErrorMessage: "Ошибка при удаление контакта",
        contactFoundMessage: "Контакт найден",
        contactNotFoundMessage: "Контакт не найден",
        errorSavingAccountWithoutData: "Не удается сохранить учетную запись с пустыми полями",
        errorSavingAccountOnlyWithSpaces: "Не удается сохранить учетную запись, где поля состоят из пробелов",
        errorSavingAccountCharactersEqualOrMore50: "Не удается сохранить учетную запись, где количество символов в поле превышает 50 символов",
        errorSavingAccountIdWithSpaces: "Не удается сохранить учетную запись, где поле id содержит пробелы",
        unsupportedAvatarImageType: "Неподдерживаемый тип изображения"
    },
    license: {
        confirmPasswordNotMatchMessage: "Пароли не совпадают!",
        unknownErrorMessage: "Произошла ошибка при активации доступа, обратитесь к администратору!",
        licenseErrorMessage: "Ошибка лицензии, обратитесь к администратору!",
        updateLicenseSuccessMessage: "Лицензия обновлена успешно!",
        updateLicenseErrorMessage: "Ошибка обновления лицензии!",
        licenseExpiresPart: 'У вас осталось {{days}} дней лицензии, обратитесь к администратору!',
        license: "Лицензия",
        licenseDetails: "Детали лицензии",
        title: "Активация доступа",
        loginLabel: "Логин администратора",
        loginPlaceholder: "Логин",
        passwordLabel: "Пароль администратора",
        passwordPlaceholder: "Пароль",
        confirmPasswordLabel: "Повторите пароль",
        confirmPasswordPlaceholder: "Повторите пароль",
        licenseTitle: "Лицензия",
        submitButton: "Активировать доступ"
    },
    mailing: {
        createMailing: "Создать рассылку",
        pending: "В ожидании..",
        inProgress: "В прогрессе",
        sent: "Отправлено",
        canceled: "Отменено",
        allContacts: "Все контакты",
        balanceBelowZero: "Баланс ниже нуля",
        waitingForConnection: "Ожидают подключения",
        sentVia: "Отправлено с помощью",
        notEnoughSymbolsInMessageError: "Сообщение слишком короткое.",
        notSelectedChannelError: "Вам нужно выбрать канал.",
        saveMailingError: "Произошла ошибка при сохранении.",
        getMailingByIdError: "Не удалось получить рассылку с сервера.",
        startMailingStartMessage: "Началась рассылка.",
        startMailingSuccessMessage: "Рассылка успешно выполнена.",
        startMailingErrorMessage: "Произошла ошибка при отправке рассылки.",
        deleteMailingSuccessMessage: "Удалено успешно",
        deleteMailingErrorMessage: "Произошла ошибка при удалении рассылки.",
        chooseOperator: "Оператор для отправки",
        needChooseOperator: "Нужно выбрать оператора",
        mailingSaved: "Рассылка успешно сохранена"
    },
    nlp: {
        question: "Вопрос",
        answer: 'Ответ',
        getScript: "Получить скрипт",
        createNewTopic: "Создать новую библиотеку",
        createNewPair: "Создать новую пару",
        topicRemoving: "Удаление темы",
        doWantDeleteTopic: "Вы хотите удалить тему?",
        selectTarget: "Выбрать цель",
        transferQuestion: "Переместить вопрос",
        move: "Переместить",
        to: "в",
        askYourQuestion: "Задайте ваш вопрос",
        unsupportedMessageType: "Неподдерживаемый тип сообщений",
        answerNotFound: "Ответ не найден",
        actualSearchString: "Фактическая строка поиска:",
        sureRemoveButton: "Вы действительно хотите удалить кнопку ",
        buttonType: "Тип кнопки",
        text: "Текст",
        value: "Значение",
        noFoundTopicPair: "Пара тем не найдена.",
        buttonTextExists: "Кнопка с таким текстом уже существует.",
        newButton: "Новая кнопка",
        buttonEditing: "Редактирование кнопки",
        saveTopicSuccessMessage: "Сохранено успешно",
        saveTopicErrorMessage: "Ошибка при сохранении темы",
        deleteTopicSuccessMessage: "Удалено успешно",
        deleteTopicErrorMessage: "Ошибка при удаление темы",
        exportPairsErrorMessage: "Ошибка при экспорте пар",
        importPairsErrorMessage: "Ошибка при импорте пар",
        importPairsSuccessMessage: "Успешный импорт пар",
        publishQuestionsSuccessMessage: "Публикация вопросов инициализирована",
        publishQuestionsErrorMessage: "Ошибка при публикации",
        saveTopicPairSuccessMessage: "Сохранено успешно",
        saveTopicPairErrorMessage: "Ошибка при сохранении",
        generateQuestionsSuccessMessage: "Вопросы сгенерированы успешно",
        generateQuestionsErrorMessage: "Ошибка при генерации вопросов",
        synonyms: "Синонимы",
        synonyms_placeholder: "привет : здравствуйте|салют|хай|здорова\nхорошо : отлично|гуд|круто\nчистый : свежий|опрятный|мытый",
        synonyms_fill: 'Заполните синонимы. Каждое слово с новой строки, затем двоеточие и его синонимы разделенные символом "|"',
        synonyms_needColon: "Отсутствует двоеточие между словом и синонимами в строке \n",
        synonyms_fewColons: "Более одного разделителя (:) в строке ",
        synonyms_wrongCharacter: "Недопустимый символ в строке \n",
        saveSynonymsSuccessMessage: "Синонимы сохранены",
        synonym_stopWord: "Ошибка. \"{{sw}}\" является стоп словом",
        synonyms_needWord: "Необходимо ввести слово \n",
        synonyms_needSynonyms: "Необходимо ввести синонимы \n",
        pipeMisplacedError: "Символ '|' не может находиться до двоеточия \n",
        synonymIsEmpty: "Синоним не может быть пустым \n",
        deleteTopicPairSuccessMessage: "Успешно удалено",
        deleteTopicPairErrorMessage: "Ошибка при удаление",
        changeTopicPairStatusSuccessMessage: "Отредактировано успешно",
        changeTopicPairStatusErrorMessage: "Ошибка изменения статуса пары тем",
        transferQuestionToTopicPairSuccessMessage: "Успешный перенос вопроса в пару тем",
        transferQuestionToTopicPairErrorMessage: "Ошибка при переносе вопроса в пару тем",
        messageBoxGetScriptMessage: "Скрипт скопирован в буфер обмена. Используйте Ctrl+V, чтобы вставить его на свой сайт.",
        messageBoxGetScriptTitle: "Скрипт",
        messageBoxDeleteTopicMessage: "Вы действительно хотите удалить библиотеку?",
        messageBoxDeleteTopicTitle: "Удаление темы",
        theme: "Тема",
        triggerName: "Название триггера",
        trigger: "Триггер",
        subject: "Тематика",
        source: "Источник",
        commonParameters: "Общие параметры",
        topicPairContentType_text: "Текст",
        topicPairContentType_markup: "Разметка",
        topicPairContentType_video: "Видео",
        topicPairContentType_audio: "Аудио",
        topicPairContentType_navigation: "Перенаправление",
        topicPairContentType_textToSpeech: "Автовоспроизведение",
        errorSendNLPSearchWhenOperatorOffline: "Перейдите в онлайн или паузу, чтобы отправить сообщение",
        errorSendNLPSearchWhenChatNotAssigned: "Выбранный чат не привязан к данному оператору",
        messageDontHaveAccessToTopic: "Извините, у вас нет доступа к этой теме",
        questionWithOnlyStopWordsError: "Вопрос <{{question}}> состоит из стоп слов",
        nlpTopicAlreadyExistsError: "Библиотека с именем {{topicName}} уже существует",
        aiSearchWithNullConfidenceError: "Произошла ошибка при поиске с помощью вектора. Забыли опубликовать топик?"
    },
    operators: {
        name: "Имя",
        displayName: "Отображаемое имя",
        createOperator: "Создать оператора",
        sureRemoveOperator: "Вы действительно хотите удалить оператора?",
        incorrectLoginMessage: "Логин содержит недопустимые символы",
        emptyNameTooltipMessage: "Имя не может быть пустым",
        emptyLoginTooltipMessage: "Логин не может быть пустым",
        emptyPasswordTooltipMessage: "Пароль не может быть пустым",
        minLengthPasswordMessage: "Пароль должен быть длиннее, чем ",
        maxLengthPasswordMessage: "Пароль должен быть короче, чем ",
        minLengthLoginMessage: "Логин должен быть длиннее, чем ",
        maxLengthLoginMessage: "Логин должен быть короче, чем ",
        changeAvatarNullIdErrorMessage: "Невозможно изменить аватар не созданного оператора",
        saveOperatorSettingsNullIdMessage: "Невозможно сохранить настройки не созданного оператора",
        spaceInLoginErrorMessage: "Логин не может содержать пробелы",
        operatorCreatingErrorMessage: "Невозможно создать несколько операторов одновременно!",
        uploadScript: "Загрузить",
        saveOperatorNoChangesMessage: "Оператор не изменился",
        saveOperatorSuccessMessage: "Сохранено успешно",
        saveOperatorErrorMessage: "Сохранение оператора прошло с ошибкой",
        duplicatedOperatorNameErrorMessage: "Оператор с таким именем уже существует",
        duplicatedOperatorLoginErrorMessage: "Оператор с таким логином уже существует",
        deleteOperatorSuccessMessage: "Удалено успешно",
        cannotDeleteYourself: "Нельзя удалить оператора, под которым Вы залогинены",
        deleteOperatorErrorMessage: "Удаление оператора прошло с ошибкой",
        cannotCheckOperatorExists: "Произошла ошибка при попытке проверить существует ли пользователь с таким логином",
        logoutOperatorByOperator: "Администратор {0} отключил Вас из системы",
        logoutOperator: "Администратор отключил Вас из системы",
        setAvatarError: "Произошла ошибка во время установки аватара",
        setAvatarSuccess: "Аватар успешно обновлен"
    },
    settings: {
        createSettings: "Создать настройку",
        settingIdCannotBeEmpty: "Невозможно сохранить настройку с пустым идентификатором.",
        confirmDeleteSetting: "Удалить настройку",
        confirmDeleteParameter: "Удалить параметр",
        settingIdAlreadyExists: "Настройка с идентификатором {id} уже существует.",
        settingId: "Название",
        key: "Ключ",
        value: "Значение",
        system: "Система",
        integration: "Интеграция",
        settingSaved: "Настройка успешно сохранена",
        settingDeleted: "Настройка удалена"
    },
    chatsPanel: {
        youAreLoggedInAs: "Вы вошли как ",
        toChangeTheUserPress: "Чтобы сменить пользователя нажмите ",
        haveBeenAssignedChannels: "Вам были назначены каналы: ",
        selectChatMessage: "Пожалуйста, выберите чат.",
        transferChatErrorMessage: "Не могу перевести чат.",
        transferChatSuccessMessage: "Чат переведен успешно.",
        errorReopenChatDueOfflineStatus: "Перейдите в онлайн, чтобы продолжить чат",
        cannotOpenChatInWidgetChannel: "Не удается открыть чат в канале виджета.",
        editMessageWrongChannelTypeMessage: "Невозможно отредактировать сообщение. Неправильный тип канала.",
        sendMessageError: "Произошла ошибка во время отправки сообщения",
        sendFileMessageError: "Произошла ошибка во время отправки файла",
    },
    permission: {
        editPermissionsFor: "Изменить разрешения для ",
        tabRole: "Роли",
        tabUsers: "Пользователи",
        selectUser: "Выберите пользователя",
        Audit_Manage: "Управление аудитом",
        Contacts_Manage: "Управление контактами",
        Chats_ManageOwnChats: "Управление своими чатами",
        Mailing_Manage: "Управление рассылками",
        Chats_ManageOthersChats: "Управление чужими чатами",
        NLP_Search: "Поиск в базе NLU",
        NLP_Manage: "Управление базой NLU",
        Operators_Manage: "Управление списком операторов и назначение каналов операторам",
        Channels_Manage: "Управление каналами",
        Integrations_Manage: "Управление настройками интеграций",
        CanManageAnalytics: "Работа с аналитикой",
        License_Manage: "Управление лицензией"
    },
    error: {
        disableWithOpenChats: "Не удалось обновить канал с идентификатором {{channelId, number}}: невозможно отключить канал, если открыты чаты: {{openedChats, number}}",
        changeTokenWhenOpenChats: "Не удалось обновить канал с идентификатором {{channelId, number}}: невозможно изменить тип или токен, если на канале есть чаты.",
        connectionError: "Ошибка соединения. Попробуйте позже.",
        invalidDateMessage: "Неверный формат даты",
        leftDateLessMessage: "Дата слева меньше даты справа",
        error: "Ошибка!",
        channelNotInitialized: "Канал не инициализирован",
        invalidTokenErrorMessage: "Пожалуйста, авторизуйтесь, чтобы продолжить",
        loginFailed: "Ошибка авторизации. Проверьте логин и пароль",
        fileEmpty: "Файл пустой и не может быть отправлен.",
        notSendMessageNotChatParticipant: "Невозможно отправить сообщение, так как оператор не является участником чата. ChatId: {{chatId, number}} OperatorId: {{operatorId, number}}",
        botBlockedByUser: "Канал заблокирован пользователем. Channel id: {{channelId, number}}, Chat id: {{chatId, number}}",
        sendMessageFailed: "Отправить сообщение не удалось. ChatId: {{chatId, number}} OperatorId: {{operatorId, number}}",
        FailedLicenseValidation: "Ошибка лицензии, обратитесь к администратору!",
        LicenseExpired: "Лицензия истекла, обратитесь к администратору!",
        LicenseAlreadyActivated: "Данная лицензия уже активирована.",
        UserAlreadyExist: "Пользователь с таким логином уже существует.",
        ChatAlreadyClose: "Чат {{chatId, number}} уже закрыт."
    },
    notification: {
        locationText: "Геолокация",
        documentText: "Документ",
        photoText: "Фото",
        newMessageInChat: "Новое сообщение в чате ",
        newChatRecieved: "Получен новый чат!",
        chatId: "ИД чата-",
        connectionError: "Ошибка соединения!",
        connectionErrorText: "Не удалось восстановить соединение, вы будете переведены в статус Offline",
        connectionAbortText: "Соединение было прервано. Обновите страницу для восстановления."
    }
};

export default translate;
