export enum ChannelType {
    Telegram = 1,
    Viber = 2,
    Vk = 3,
    Widget = 4,
    Facebook = 5,
    WeChat = 6,
    Instagram = 7,
    UniformWidget = 8,
    EdnaWhatsApp = 9,
    WhatsApp = 10,
    VoiceChannel = 11,
    DevinoWhatsApp = 12
}

export const channelTypeToString = (channelType: ChannelType): string => {
    switch(channelType) {
        case ChannelType.Telegram:
            return "Telegram";
        case ChannelType.Viber:
            return "Viber";
        case ChannelType.Vk:
            return "Vk";
        case ChannelType.Widget:
            return "Widget";
        case ChannelType.Facebook:
            return "Facebook";
        case ChannelType.WeChat:
            return "WeChat";
        case ChannelType.Instagram:
            return "Instagram";
        case ChannelType.UniformWidget:
            return "UniformWidget";
        case ChannelType.EdnaWhatsApp:
            return "EdnaWhatsApp";
        case ChannelType.WhatsApp:
            return "WhatsApp";
        case ChannelType.VoiceChannel:
            return "VoiceChannel";
        case ChannelType.DevinoWhatsApp:
            return "DevinoWhatsApp";
    }
}