import React, { useState, useEffect, useRef } from "react";

import "./ChooseFileDialog.css"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { showErrorToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import i18n from "src/app/shared/localization/i18n";

export enum FileType{
    Image = 0,
    File = 1,
    Archive = 2
}

interface chooseFileDialogProps {
    label?: string;
    isDisabled?: boolean;
    onFileChoosen(file: File, type: FileType): void;
}

export default function ChooseFileDialog(props: chooseFileDialogProps) {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
    let selectedFileType = FileType.File;


    const _onFileSelected = (e: any) => {
        if(e.target.files.length === 0) return;
        const file = e.target.files[0] as File;

        if(file.size > 0) {
            props.onFileChoosen(file, selectedFileType);
            // Task #380 - fix for IOS files uploading
            setTimeout(() => {
                document.getElementsByClassName('input-file__hidden')[0].removeAttribute('type');
                document.getElementsByClassName('input-file__hidden')[0].removeAttribute('accept');
            }, 1000)
        }
        else {
            showErrorToastr(i18n.t('error.fileEmpty'));
        }    
    }

    const buildFileSelector = (accept: string) => {
        const fileSelector = inputRef.current;
        if(!fileSelector) return;
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('accept', accept);
        fileSelector.onchange = _onFileSelected;
        return fileSelector;
    }

    const _onMenuChoosenHandler = (type: FileType): void => {
        selectedFileType = type;
        let accept = "";
        switch(type){
            case FileType.Image: {
                accept = "image/jpeg, image/pjpeg, image/png, image/gif";
                break;
            }
            case FileType.Archive: {
                accept = ".zip";
                break;
            }
        }
        buildFileSelector(accept)?.click();
        setIsMenuVisible(false);
    }

    return (
        <React.Fragment >
            <input ref={inputRef} className='input-file__hidden'/>
            <Dropdown className="choose-file-dialog__container" isOpen={isMenuVisible} toggle={() => {setIsMenuVisible(!isMenuVisible)}} disabled={props.isDisabled}>
                <DropdownToggle tag="div" onClick={props.label != null ? () => _onMenuChoosenHandler(FileType.Archive) : undefined}>
                    <i className={(props.label == null ? "material-icons choose-file-dialog__attach-image" : "choose-file-dialog__label") + (props.isDisabled ? " disabled" : "")}>attach_file</i>
                    {props.label == null && <img /> || <div>{props.label}</div>}
                    {(isMenuVisible && props.label == null) &&
                    <DropdownMenu style={{}} className="choose-file-dialog__menu">
                            <div onClick={() => { _onMenuChoosenHandler(FileType.Image) }} className="choose-file-dialog__menu-item">{t('common.image')}</div>
                            <div onClick={() => { _onMenuChoosenHandler(FileType.File) }} className="choose-file-dialog__menu-item">{t('common.file')}</div>
                    </DropdownMenu>}
                </DropdownToggle>                
            </Dropdown>
        </React.Fragment>
    )
}