import {Action, Reducer} from "redux";

import {ITopic} from "./models/Topic";
import {initState} from "./state/InitKnowledgeBaseState";
import {TopicPairStatus} from "./models/enums/TopicPairStatus";
import { IKnowledgeBaseState } from "./state/KnowledgeBaseState";
import { SortingOption } from "src/app/nlp/~store/models/enums/SortingOption"

import {
    GetAllTopicsSuccessAction,
    SaveTopicAction,
    DeleteTopicAction,
    CreateTopicAction,
    CancelCreateTopicAction,
    ChangeSelectedTopicPairStatusAction,
    CreateTopicPairAction,
    CancelCreateTopicPairAction,
    SaveTopicPairAction,
    SetTransferQuestionPopupDataAction,
    GetAllTopicsAction,
    UpdateTargetTopicPairIdAction,
    SetMessageBoxDataAction,
    GetTopicByExternalIdSuccessAction,
    AddQuestionAction,
    GetAnswersSussessAction,
    GetTopicByExternalIdAction,
    GetTopicPairsByTopicNameWithFilterActionSuccess,
    GetTopicPairsCategoriesByTopicNameActionSuccess,
    SetFilterTagsActionSuccess,
    GetQuestionsByTopicNameActionSuccess,
    SetIsPublishingQuestionsStatusAction
} from "./actions/interfaces";
import {
    CREATE_TOPIC,
    CANCEL_CREATE_TOPIC,
    GET_TOPIC_BY_EXTERNAL_ID_START,
    GET_TOPIC_BY_EXTERNAL_ID_SUCCESS,
    GET_TOPIC_BY_EXTERNAL_ID_ERROR,
    CREATE_TOPIC_PAIR,
    CANCEL_CREATE_TOPIC_PAIR,
    CHANGE_SELECTED_TOPIC_PAIR_STATUS,
    SET_TRANSFER_QUESTION_POPUP_DATA,
    TOGGLE_TRANSFER_QUESTION_POPUP_VISIBILITY,
    GET_ALL_TOPICS_SUCCESS,
    GET_ALL_TOPICS_START,
    GET_ALL_TOPICS_ERROR,
    UPDATE_TARGET_TOPIC_PAIR_ID,
    EXPORT_PAIRS_START,
    EXPORT_PAIRS_ERROR,
    EXPORT_PAIRS_SUCCESS,
    PUBLISH_QUESTIONS_START,
    PUBLISH_QUESTIONS_ERROR,
    PUBLISH_QUESTIONS_SUCCESS,
    SAVE_TOPIC_START,
    SAVE_TOPIC_SUCCESS,
    SAVE_TOPIC_ERROR,
    DELETE_TOPIC_START,
    DELETE_TOPIC_ERROR,
    SAVE_TOPIC_PAIR_START,
    SAVE_TOPIC_PAIR_ERROR,
    SAVE_TOPIC_PAIR_SUCCESS,
    DELETE_TOPIC_PAIR_START,
    DELETE_TOPIC_PAIR_ERROR,
    CHANGE_TOPIC_PAIR_STATUS_START,
    CHANGE_TOPIC_PAIR_STATUS_ERROR,
    TRANSFER_QUESTION_TO_TOPIC_PAIR_START,
    TRANSFER_QUESTION_TO_TOPIC_PAIR_ERROR,
    TOGGLE_MESSAGE_BOX_VISIBILITY,
    SET_MESSAGE_BOX_DATA,
    TOGGLE_TOPIC_WIDGET_VISIBILITY,
    ADD_QUESTION,
    GET_ANSWERS_START,
    GET_ANSWERS_SUCCESS,
    GET_ANSWERS_ERROR,
    CLEAR_TOPIC_WIDGET_MESSAGES,
    GET_TOPIC_PAIRS_BY_TOPIC_NAME_WITH_FILTER_SUCCESS,
    GET_TOPIC_PAIR_CATEGORIES_SUCCESS,
    SET_FILTER_TAGS_SUCCESS,
    GET_QUESTIONS_BY_TOPIC_NAME_SUCCESS,
    TOGGLE_TOPIC_SORTING_BY_ID,
    TOGGLE_TOPIC_SORTING_BY_NAME,
    GENERATE_QUESTIONS_START,
    GENERATE_QUESTIONS_SUCCESS,
    GENERATE_QUESTIONS_ERROR,
    SET_IS_PUBLISHING_QUESTIONS_STATUS
} from "./actions/types";
import {IQuestionMessage} from "./models/messages/QuestionMessage";
import {MessageType} from "./models/enums/MessageType";
import {IAnswerMessage} from "./models/messages/AnswerMessage";
import { TopicPairContentType } from "./models/enums/TopicPairContentType";

export type KnownAction =
    | GetAllTopicsAction
    | SaveTopicAction
    | DeleteTopicAction
    | CreateTopicAction
    | CancelCreateTopicAction
    | GetTopicByExternalIdAction
    | SaveTopicPairAction
    | CreateTopicPairAction
    | CancelCreateTopicPairAction
    | UpdateTargetTopicPairIdAction
    | SetTransferQuestionPopupDataAction;

export const knowledgeBaseReducer: Reducer<IKnowledgeBaseState> = (
    state: IKnowledgeBaseState = initState,
    incomingAction: Action
): IKnowledgeBaseState => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        // main
        case SAVE_TOPIC_START:
        case DELETE_TOPIC_START:
        case GET_ALL_TOPICS_START:
        case SAVE_TOPIC_PAIR_START:
        case DELETE_TOPIC_PAIR_START:
        case CHANGE_TOPIC_PAIR_STATUS_START:
        case GET_TOPIC_BY_EXTERNAL_ID_START:
        case TRANSFER_QUESTION_TO_TOPIC_PAIR_START: {
            return {...state, isOperationInProgress: true};
        }

        case SAVE_TOPIC_ERROR:
        case DELETE_TOPIC_ERROR:
        case GET_ALL_TOPICS_ERROR:
        case SAVE_TOPIC_PAIR_ERROR:
        case DELETE_TOPIC_PAIR_ERROR:
        case CHANGE_TOPIC_PAIR_STATUS_ERROR:
        case GET_TOPIC_BY_EXTERNAL_ID_ERROR:
        case TRANSFER_QUESTION_TO_TOPIC_PAIR_ERROR: {
            return {...state, isOperationInProgress: false};
        }

        case GET_ALL_TOPICS_SUCCESS: {
            const topics = (action as GetAllTopicsSuccessAction).payload.topics;
            return {...state, isOperationInProgress: false, main: {...state.main, topics}};
        }

        case CREATE_TOPIC:
            const newTopic = {name: "", topicId: 0, parametersJson: '{}'} as ITopic;
            return {...state, main: {...state.main, topics: [newTopic, ...state.main.topics], isCreatingTopic: true}};

        case CANCEL_CREATE_TOPIC:
            return {...state, main: {...state.main, topics: state.main.topics.filter(t => t.topicId !== 0), isCreatingTopic: false}, isOperationInProgress: false};

        case SAVE_TOPIC_SUCCESS:
            return {...state, main: {...state.main, isCreatingTopic: false}};

        // topic editor
        case EXPORT_PAIRS_START: {
            return { ...state, topicEditor: { ...state.topicEditor, isExportingPairs: true } };
        }

        case EXPORT_PAIRS_SUCCESS:
        case EXPORT_PAIRS_ERROR: {
            return { ...state, topicEditor: { ...state.topicEditor, isExportingPairs: false } };
        }

        case PUBLISH_QUESTIONS_START: {
            return {...state, topicEditor: {...state.topicEditor, isPublishingQuestions: true}};
        }

        case PUBLISH_QUESTIONS_SUCCESS: {
            return {...state, topicEditor: {...state.topicEditor}};
        }

        case PUBLISH_QUESTIONS_ERROR: {
            return {...state, topicEditor: {...state.topicEditor, isPublishingQuestions: false}};
        }

        case SET_IS_PUBLISHING_QUESTIONS_STATUS: {
            const status = (action as SetIsPublishingQuestionsStatusAction).payload.status;            
            return {...state, topicEditor: {...state.topicEditor, isPublishingQuestions: status}};
        }

        case GET_TOPIC_BY_EXTERNAL_ID_SUCCESS: {
            const topic = (action as GetTopicByExternalIdSuccessAction).payload.topic;
            return {
                ...state,
                isOperationInProgress: false,
                topicEditor: {
                    ...state.topicEditor,
                    selectedTopic: topic,
                    isCreatingTopicPair: false,
                    selectedTopicPairStatus: state.topicEditor.selectedTopicPairStatus
                        ? state.topicEditor.selectedTopicPairStatus
                        : TopicPairStatus.Active
                }
            };
        }

        case CREATE_TOPIC_PAIR: {
            const { topicPairStatus, defaultContentType } = (action as CreateTopicPairAction).payload;
            const editedTopicPairs = state.topicEditor.selectedTopic.topicPairs || [];
            const newTopicPair = {
                answer: {
                    text: "",
                    alternativeText1: "",
                    alternativeText2: "",
                    answerId: 0,
                    buttons: [],
                    parametersJson: {}
                },
                topicPairId: 0,
                triggerName: "",
                subject: "",
                source: "",
                status: topicPairStatus,
                questions: [
                    {
                        text: "",
                        isDefault: true,
                        questionId: 0
                    }
                ],
                contentType: defaultContentType
            };

            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    isCreatingTopicPair: true,
                    selectedTopic: {
                        ...state.topicEditor.selectedTopic,
                        topicPairs: [newTopicPair, ...editedTopicPairs]
                    }
                }
            };
        }

        case CANCEL_CREATE_TOPIC_PAIR: {
            const editedTopicPairs = state.topicEditor.selectedTopic.topicPairs || [];
            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    isCreatingTopicPair: false,
                    selectedTopic: {
                        ...state.topicEditor.selectedTopic,
                        topicPairs: [...editedTopicPairs.filter(etp => etp.topicPairId !== 0)]
                    }
                }
            };
        }

        case SAVE_TOPIC_PAIR_SUCCESS: {
            return {...state, topicEditor: {...state.topicEditor, isCreatingTopicPair: false}};
        }

        case CHANGE_SELECTED_TOPIC_PAIR_STATUS: {
            const topicPairStatus = (action as ChangeSelectedTopicPairStatusAction).payload.topicPairStatus;
            return {...state, topicEditor: {...state.topicEditor, selectedTopicPairStatus: topicPairStatus}};
        }

        // transfer question popup
        case UPDATE_TARGET_TOPIC_PAIR_ID: {
            const targetTopicPairId = (action as UpdateTargetTopicPairIdAction).payload.targetTopicId;
            const transferQuestionPopup = state.topicEditor.transferQuestionPopup;
            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    transferQuestionPopup: {
                        ...transferQuestionPopup,
                        popupData: {...transferQuestionPopup.popupData, targetTopicPairId}
                    }
                }
            };
        }

        case SET_TRANSFER_QUESTION_POPUP_DATA: {
            const {topicExternalId, topicPairId, questionId} = (action as SetTransferQuestionPopupDataAction).payload;
            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    transferQuestionPopup: {
                        ...state.topicEditor.transferQuestionPopup,
                        popupData: {topicExternalId, topicPairId, questionId, targetTopicPairId: 0}
                    }
                }
            };
        }

        case TOGGLE_TRANSFER_QUESTION_POPUP_VISIBILITY: {
            const isTransferQuestionPopupVisible = !state.topicEditor.transferQuestionPopup.isTransferQuestionPopupVisible;
            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    transferQuestionPopup: {...state.topicEditor.transferQuestionPopup, isTransferQuestionPopupVisible}
                }
            };
        }

        // message box
        case TOGGLE_MESSAGE_BOX_VISIBILITY: {
            const isMessageBoxVisible = !state.topicEditor.messageBox.isMessageBoxVisible;
            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    messageBox: {
                        ...state.topicEditor.messageBox,
                        isMessageBoxVisible
                    }
                }
            };
        }

        case SET_MESSAGE_BOX_DATA: {
            const {title, message, type, className, onClose, onConfirm, onReject} = (action as SetMessageBoxDataAction).payload;
            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    messageBox: {
                        ...state.topicEditor.messageBox,
                        data: {
                            className,
                            title,
                            message,
                            type,
                            onClose,
                            onConfirm,
                            onReject
                        }
                    }
                }
            };
        }

        // topic widget
        case TOGGLE_TOPIC_WIDGET_VISIBILITY: {
            const isTopicWidgetVisible = !state.topicEditor.topicWidget.isTopicWidgetVisible;
            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    topicWidget: {
                        ...state.topicEditor.topicWidget,
                        isTopicWidgetVisible
                    }
                }
            };
        }

        case ADD_QUESTION: {
            const questionText = (action as AddQuestionAction).payload.questionText;
            const message = {
                questionText,
                type: MessageType.Question,
                createdDate: new Date(),
            } as IQuestionMessage;

            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    topicWidget: {
                        ...state.topicEditor.topicWidget,
                        messages: [...state.topicEditor.topicWidget.messages, message]
                    }
                }
            };
        }

        case GET_ANSWERS_START: {
            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    topicWidget: {
                        ...state.topicEditor.topicWidget,
                        isGetAnswerInProgress: true,
                    }
                }
            };
        }

        case GET_ANSWERS_SUCCESS: {
            const answers = (action as GetAnswersSussessAction).payload.answers;
            const questionText = (action as GetAnswersSussessAction).payload.questionText;

            const message = {
                type: MessageType.Answer,
                answers,
                questionText,
                createdDate: new Date()
            } as IAnswerMessage;

            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    selectedTopic: {
                        ...state.topicEditor.selectedTopic,
                        topicPairs: answers.filter(a => a.topicPair != null).map((item) => item.topicPair)
                    },
                    topicWidget: {
                        ...state.topicEditor.topicWidget,
                        messages: [...state.topicEditor.topicWidget.messages, message],
                        isGetAnswerInProgress: false
                    }
                }
            };
        }

        case GET_ANSWERS_ERROR: {
            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    topicWidget: {
                        ...state.topicEditor.topicWidget,
                        isGetAnswerInProgress: false
                    }
                }
            };
        }

        case CLEAR_TOPIC_WIDGET_MESSAGES: {
            return {
                ...state,
                topicEditor: {
                    ...state.topicEditor,
                    topicWidget: {
                        ...state.topicEditor.topicWidget,
                        messages: []
                    }
                }
            };
        }

        case GET_TOPIC_PAIRS_BY_TOPIC_NAME_WITH_FILTER_SUCCESS: {
            const {topic} = (action as GetTopicPairsByTopicNameWithFilterActionSuccess).payload;

            return { 
                ...state,
                topicPairsSearch: {
                    ...state.topicPairsSearch,
                    selectedTopic: topic
                }
            };
        }

        case GET_TOPIC_PAIR_CATEGORIES_SUCCESS: {
            const {topicPairCategories, topicPairSources} = (action as GetTopicPairsCategoriesByTopicNameActionSuccess).payload;

            return { 
                ...state,
                topicPairsSearch: {
                    ...state.topicPairsSearch,
                    topicPairCategories,
                    topicPairSources
                }
            };
        }

        case SET_FILTER_TAGS_SUCCESS: {
            const {filterTags} = (action as SetFilterTagsActionSuccess).payload;

            return { 
                ...state,
                filterTags
            };
        }

        case GET_QUESTIONS_BY_TOPIC_NAME_SUCCESS: {
            const { questions } = (action as GetQuestionsByTopicNameActionSuccess).payload;
            return {
                ...state,
                topicQuestion: {
                    ...state.topicQuestion,
                    questions
                }
                }
            };

        case TOGGLE_TOPIC_SORTING_BY_ID: {
            if (state.main.isSortingByTopicId == SortingOption.Increase) {
                return {
                    ...state,
                    main: {
                        ...state.main,
                        isSortingByTopicId: SortingOption.Decrease,
                        isSortingByTopicName: SortingOption.None
                    }
                }
            }
            else {
                return {
                    ...state,
                    main: {
                        ...state.main,
                        isSortingByTopicId: SortingOption.Increase,
                        isSortingByTopicName: SortingOption.None
                    }
                }
            }
        }

        case TOGGLE_TOPIC_SORTING_BY_NAME: {
            if (state.main.isSortingByTopicName == SortingOption.Increase) {
                return {
                    ...state,
                    main: {
                        ...state.main,
                        isSortingByTopicName: SortingOption.Decrease,
                        isSortingByTopicId: SortingOption.None
                    }
                }
            }
            else {
                return {
                    ...state,
                    main: {
                        ...state.main,
                        isSortingByTopicName: SortingOption.Increase,
                        isSortingByTopicId: SortingOption.None
                    }
                }
            }
        }

        case GENERATE_QUESTIONS_START: {
            return {...state, topicEditor: {...state.topicEditor, isGeneratingQuestions: true}};
        }

        case GENERATE_QUESTIONS_SUCCESS:
        case GENERATE_QUESTIONS_ERROR: {
            return {...state, topicEditor: {...state.topicEditor, isGeneratingQuestions: false}};
        }

        default:
            return state;
    }
};
