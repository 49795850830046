import {AppThunkAction} from 'src/~store/models/AppThunkAction';

import {IOperator} from '../models/Operator';
import {OperatorRole} from '../models/enums/OperatorRole';

import { 
    GetAllOperators, 
    SaveOperator,
    DeleteOperator,
    CreateOperatorAction,
    ChangeOperatorAvatar,
    SaveOperatorSettingAction,
    ToggleOperatorSortingAction,
} from './interfaces';

import { 
    GET_ALL_OPERATORS_START, 
    GET_ALL_OPERATORS_SUCCESS, 
    GET_ALL_OPERATORS_ERROR, 
    SAVE_OPERATOR_START, 
    SAVE_OPERATOR_SUCCESS, 
    SAVE_OPERATOR_ERROR, 
    DELETE_OPERATOR_START,
    DELETE_OPERATOR_SUCCESS,
    DELETE_OPERATOR_ERROR,
    CREATE_OPERATOR,
    SAVE_NEW_OPERATOR_SUCCESS,
    DELETE_NEW_OPERATOR_SUCCESS,
    SAVE_OPERATOR_SETTING,
    TOGGLE_OPERATOR_SORTING as TOGGLE_OPERATOR_SORTING,
} from './types';

import { 
    getAllOperatorsAsync,
    deleteIdentityAsync,
    saveOperatorAsync,
    updateIdentityAsync,
    deleteOperatorAsync,
    changeOperatorAvatarAsync,
    getSettingByOperatorIdAsync,
    saveOperatorSettingAsync,
    deleteOperatorSettingAsync,
    registerOperatorAsync,
    getSettingsByOperatorsIdAsync,
    checkIfUserExistsAsync
} from '../../~api/actions';
import {showSuccessToastr, showErrorToastr} from 'src/app/shared/helpers/toastr-helper/ToastrHelper';

import {
    DUPLICATED_OPERATOR_NAME_RESPONSE_MESSAGE,
    DUPLICATED_OPERATOR_LOGIN_RESPONSE_MESSAGE
} from './errorMessages';

import { SAVE_NEW_CHANNEL_ERROR } from 'src/app/channel/~store/actions/types';
import { OperatorType } from '../models/enums/OperatorType';
import { IApiActionResult } from 'src/~api/ApiActionResult';
import { initNewSetting } from 'src/app/setting/~store/state/InitSettingsState';
import { OPERATOR_SETTING_PREFIX } from '../../operators-main/operators-list/operator-item/OperatorItemConstants';
import i18n from "src/app/shared/localization/i18n";

// operators list

export const getAllOperators = (): AppThunkAction<GetAllOperators> => async dispatch => {
    dispatch({type: GET_ALL_OPERATORS_START});

    const result = await getAllOperatorsAsync();

    if(result.isSuccess) {
        const operators = result.value ? result.value : [];
        
        const settingResult = await getSettingsByOperatorsIdAsync(operators.map(o => o.operatorId));

        if(settingResult.isSuccess) {
            operators.forEach(operator => operator.operatorSetting = settingResult.value?.find(s => s.id === OPERATOR_SETTING_PREFIX + operator.operatorId) || initNewSetting());
            dispatch( {type: GET_ALL_OPERATORS_SUCCESS, payload: {operators}} );
            return;
        }
    }

    dispatch({type: GET_ALL_OPERATORS_ERROR});
};

export const saveOperator = (
    oldOperator: IOperator, 
    newOperator: IOperator,
    scriptFile?: File,
): AppThunkAction<SaveOperator> => async (dispatch, getState) => {
    dispatch({type: SAVE_OPERATOR_START});
    if(
        (oldOperator.login === newOperator.login) &&
        (oldOperator.displayName === newOperator.displayName) &&
        (oldOperator.name === newOperator.name) &&
        (oldOperator.password === newOperator.password) &&
        (oldOperator.type === newOperator.type) &&
        (oldOperator.description === newOperator.description) &&
        (oldOperator.enabled === newOperator.enabled) &&
        (oldOperator.operatorId !== undefined) &&
        (scriptFile === undefined)) {
            showSuccessToastr(i18n.t('operators.saveOperatorNoChangesMessage'));
            dispatch({type: SAVE_OPERATOR_ERROR})
            return;
    }

    if (newOperator.type !== OperatorType.Bot && oldOperator.login !== newOperator.login) {
        const userExistsResult = await checkIfUserExistsAsync(newOperator.login);

        if (!userExistsResult.isSuccess) {
            showErrorToastr(i18n.t('operators.cannotCheckOperatorExists'));
            dispatch({ type: SAVE_OPERATOR_ERROR })
            return;
        }
        if (userExistsResult.value === true) {
            showErrorToastr(i18n.t('operators.duplicatedOperatorLoginErrorMessage'));
            dispatch({ type: SAVE_OPERATOR_ERROR })
            return;
        }
    }


    const result = await saveOperatorAsync(newOperator, getState().chatItemsState.operatorId, scriptFile);
    if (result.isSuccess) {
        if(newOperator.operatorId !== 0 && newOperator.type !== OperatorType.Bot) {
            if (oldOperator.operatorId !== undefined) {
                let role: OperatorRole | undefined = undefined;
                switch(result.value?.type) {
                    case OperatorType.Operator:
                        role = OperatorRole.Operator;
                        await updateIdentityAsync(oldOperator.operatorId, newOperator.login, newOperator.password, newOperator.name, role, newOperator.enabled);
                        break;
                    case OperatorType.Supervisor:
                        role = OperatorRole.Supervisor;
                        await updateIdentityAsync(oldOperator.operatorId, newOperator.login, newOperator.password, newOperator.name, role, newOperator.enabled);
                        break;
                    case OperatorType.Bot:
                        if(oldOperator.type !== OperatorType.Bot) {
                            await deleteIdentityAsync({operatorId: newOperator.operatorId} as IOperator)
                        }
                        break;
                    default:
                        role = undefined;
                }
            }
        }
        if(newOperator.operatorId === 0 && newOperator.type !== OperatorType.Bot) {
            const operator: IOperator = result.value ?? {} as IOperator;
            const role = newOperator.type === OperatorType.Supervisor ? OperatorRole.Supervisor : OperatorRole.Operator;
            const registerResult = await registerOperatorAsync(
                operator.login,
                newOperator.password,
                operator.name,
                role.toString(),
                operator.operatorId ?? 0,
                operator.enabled
            );
            if(!registerResult.isSuccess) {
                await deleteOperatorAsync(operator);
                await deleteIdentityAsync(operator);
                dispatch({type: SAVE_NEW_CHANNEL_ERROR});
                return;
            }
            dispatch({type: SAVE_NEW_OPERATOR_SUCCESS});
        }
        dispatch({type: SAVE_OPERATOR_SUCCESS});
        showSuccessToastr(i18n.t('operators.saveOperatorSuccessMessage'));
    } else {
        if(result.errorText !== undefined) {
            console.log(result.errorText);
            if (result.errorText.includes(DUPLICATED_OPERATOR_NAME_RESPONSE_MESSAGE)) {
                showErrorToastr(i18n.t('operators.duplicatedOperatorNameErrorMessage'));
            }
            else if (result.errorText.includes(DUPLICATED_OPERATOR_LOGIN_RESPONSE_MESSAGE)) {
                showErrorToastr(i18n.t('operators.duplicatedOperatorLoginErrorMessage'));
            }
            else {
                showErrorToastr(i18n.t('operators.saveOperatorErrorMessage'));
            }
        } else {
            showErrorToastr(i18n.t('operators.saveOperatorErrorMessage'));
        }
        dispatch({type: SAVE_OPERATOR_ERROR});
    }

    getAllOperators()(dispatch, getState);
}

export const deleteOperator = (operator: IOperator): AppThunkAction<DeleteOperator> => async (dispatch, getState) => {
    dispatch({type: DELETE_OPERATOR_START});
    if(operator.operatorId === undefined) {
        dispatch({type: DELETE_NEW_OPERATOR_SUCCESS});
        dispatch({type: DELETE_OPERATOR_SUCCESS});
    } else {
        const result = await deleteOperatorAsync(operator, getState().chatItemsState.operatorId);
        let result2: IApiActionResult<void> = {isSuccess: false} as IApiActionResult<void>;
        if (operator.type !== OperatorType.Bot && result.isSuccess)
            result2 = await deleteIdentityAsync(operator);
        else
            result2.isSuccess = true;

        if (result.isSuccess && result2.isSuccess) {
            dispatch({type: DELETE_OPERATOR_SUCCESS});
            showSuccessToastr(i18n.t('operators.deleteOperatorSuccessMessage'));
        } else {
            dispatch({ type: DELETE_OPERATOR_ERROR });
            const error = JSON?.parse(result.errorText ?? "null");
            if (error.errorCode === "operators.cannotDeleteYourself")
                showErrorToastr(i18n.t("operators.cannotDeleteYourself"));
            else
                showErrorToastr(i18n.t('operators.deleteOperatorErrorMessage'));
        }
    }

    getAllOperators()(dispatch, getState);
}

export const createOperator = (): AppThunkAction<CreateOperatorAction> => async dispatch => {
    dispatch({type: CREATE_OPERATOR}); 
}

export const changeOperatorAvatar = (
    file: File, 
    operator: IOperator, 
    onImageChangeSuccess: (imageId: string | null) => void
): AppThunkAction<ChangeOperatorAvatar> => async (dispatch, getState) => {
    const result = await changeOperatorAvatarAsync(file, operator);

    if (!result.isSuccess) {
        showErrorToastr(i18n.t('operators.setAvatarError'));
    }
    else {
        showSuccessToastr(i18n.t('operators.setAvatarSuccess'));
        const imageId: string | null = result.value ? result.value.avatarId : null; 
        onImageChangeSuccess?.(imageId);
    }
}

export const saveOperatorSetting = (
    operator: IOperator, 
    setting: string
): AppThunkAction<SaveOperatorSettingAction> => async (dispatch) => {
    const result = await saveOperatorSettingAsync(operator.operatorId as number, setting);

    if (result.isSuccess) {
        showSuccessToastr(i18n.t('settings.settingSaved'));
        dispatch({type: SAVE_OPERATOR_SETTING, 
            payload: {
                setting, 
                operator
            }
        });
    }
}

export const toggleOperatorSorting = (): AppThunkAction<ToggleOperatorSortingAction> => async dispatch => {
    dispatch({type: TOGGLE_OPERATOR_SORTING})
}
