import * as React from "react";
import { IChannelItemProps } from "./~types/ChannelItemProps";
import {
  channelTypeToString,
  ChannelType,
} from "src/app/channel/~store/models/enums/ChannelType";
import { Button, Input, Tooltip, Label } from "reactstrap";
import copy from "copy-to-clipboard";
import getScript from "../../../../shared/helpers/script-helper/ScriptHelper";

import "./ChannelItem.css";
import { IChannelItemState } from "./~types/ChannelItemState";
import { IChannel } from "src/app/channel/~store/models/Channel";
import { showErrorToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import ChannelOperatorList from "./channel-operator-list/ChannelOperatorList";
import { ModalWindow } from "src/app/shared/components/modal-window/ModalWindow";
import { IChannelOperator } from "src/app/channel/~store/models/ChannelOperator";
import SettingsItem from "src/app/setting/settings-main/settings-list/setting-item/SettingItem";
import i18n from "src/app/shared/localization/i18n";
import { OnChangeValue } from "react-select";

export default class ChannelItem extends React.Component<
  IChannelItemProps,
  IChannelItemState
> {
  public readonly state: IChannelItemState = {
    token: this.props.channel.token,
    name: this.props.channel.name,
    type: this.props.channel.type,
    enabled: this.props.channel.enabled,
    started: this.props.channel.started,
    nameTooltipMessage: "",
    isChannelSettingOpen: false,
    isNameInvalid: false,
    isNameTooltipOpen: false,
    isModalVisible: false,
    isTokenInvalid: false,
  };

  public render() {
    const {
      token,
      name,
      type,
      enabled,
      started,
      isNameInvalid,
      isNameTooltipOpen,
      nameTooltipMessage,
      isTokenInvalid,
    } = this.state;

    const { allOperatorsShort } = this.props;
    const { channelId } = this.props.channel;
    const channelOperators = this.props.channel.operators;
    return (
      <React.Fragment>
        <div className="channel-item">
          <div className="channel-item-content">
          <span className="channel-item-id">#{this.props.channel.channelId}</span> 
            <div className="channel-item__name-cell">
              <Input
                className="channel-item__input-name"
                id={"channelname" + this.props.channel.channelId}
                value={name}
                invalid={isNameInvalid}
                onChange={this._onNameChangeHandler}
                onMouseEnter={this._onNameInputMouseEnterHandler}
                onMouseLeave={this._onNameInputMouseLeaveHandler}
                autoFocus={this.props.channel.channelId === 0}
              />
              <Tooltip
                placement="bottom-start"
                isOpen={isNameTooltipOpen}
                target={"channelname" + this.props.channel.channelId}
              >
                {nameTooltipMessage}
              </Tooltip>
            </div>
            <div>
              <Input
                type="select"
                className="channel-item__channel-type"
                disabled={this.props.channel.hasChats}
                defaultValue={channelTypeToString(type)}
                onChange={this._onTypeChangeHandler}
              >
                <option>{channelTypeToString(ChannelType.Telegram)}</option>
                <option>{channelTypeToString(ChannelType.Viber)}</option>
                <option>{channelTypeToString(ChannelType.Vk)}</option>
                <option>{channelTypeToString(ChannelType.Widget)}</option>
                <option>{channelTypeToString(ChannelType.Facebook)}</option>
                <option>{channelTypeToString(ChannelType.WeChat)}</option>
                <option>{channelTypeToString(ChannelType.Instagram)}</option>
                <option>{channelTypeToString(ChannelType.UniformWidget)}</option>
                <option>{channelTypeToString(ChannelType.EdnaWhatsApp)}</option>
                <option>{channelTypeToString(ChannelType.WhatsApp)}</option>
                <option>{channelTypeToString(ChannelType.VoiceChannel)}</option>
                <option>{channelTypeToString(ChannelType.DevinoWhatsApp)}</option>
              </Input>
            </div>
            <div>
              <Input
                type="text"
                readOnly={this.props.channel.hasChats}
                className="channel-item__channel-token"
                value={token}
                onChange={this._onTokenChangeHandler}
              ></Input>
            </div>
            <div className="channel-item__enabled-cell">
              <Label check={enabled}>
                <Input
                  type="checkbox"
                  name={"checkbox" + channelId}
                  checked={enabled}
                  onChange={this._onEnabledChangeHandler}
                />
                <span
                  className={
                    "channel-item__is-enabled " + (!started && "not-started")
                  }
                >
                  {""}
                </span>
              </Label>
            </div>
            <div className="channel-item__channel-actions">
              {this.props.channel.channelId != 0 &&
                this.props.channel.type === ChannelType.Widget && (
                  <Button
                    className="channel-item__btn-getScript"
                    onClick={this._getScript}
                  >
                    <i className="material-icons">code</i>
                  </Button>
                )}

              {this.props.channel.channelId != 0 && (
                <SettingsItem
                  setting={this.props.channel.setting}
                  onSettingSave={(setting) =>
                    this._onSaveChannelSettingHandler(setting.value)
                  }
                />
              )}
              <Button
                className="channel-item__btn-save"
                onClick={() => this._onSaveChannelHandler()}
              >
                <i className="material-icons">save</i>
              </Button>
              {this.props.channel.channelId != 0 && (
                            <Button className="channel-item__btn-restart" onClick={this._onRestartChannelHandler}>
                <i className="material-icons">restart_alt</i>
              </Button>)
              }
              <Button
                className="channel-item__btn-remove"
                onClick={this._onDeleteChannelHandler}
              >
                <i className="material-icons">delete_forever</i>
              </Button>
            </div>
          </div>
          <div className="channel-item__operators-row">
            <div className="channel-item__operators">
              <ChannelOperatorList
                channelOperators={channelOperators}
                allOperatorsShort={allOperatorsShort}
                channelId={this.props.channel.channelId ?? 0}
                onSaveChannelOperators={(channelOperators) =>
                  this._onSaveChannelHandler(channelOperators)
                }
              />
            </div>
          </div>
        </div>
        <ModalWindow
          isOpen={this.state.isModalVisible}
          header={<div>{i18n.t('channels.copyScriptHeader')}</div>}
          body={<div>{i18n.t('channels.copyScriptBody')}</div>}
          footer={
            <Button
              onClick={() => {
                this.setState({ isModalVisible: false });
              }}
            >
              Close
            </Button>
          }
        ></ModalWindow>
      </React.Fragment>
    );
  }

  private _getScript = (): void => {
    const url: string = window.location.protocol + "//" + window.location.host;
    copy(getScript(this.props.channel.token, url));
    this.setState({ isModalVisible: true });
  };

  private _onTokenChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const token = event.currentTarget.value;
    let isTokenInvalid: boolean = false;
      if (token === "") {
          isTokenInvalid = true;
      }
      this.setState({ token, isTokenInvalid });
  };

  private _onNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = event.currentTarget.value;
    let isNameInvalid: boolean = false;

      if (name === "") {
          isNameInvalid = true;
          const nameTooltipMessage: string = i18n.t('channels.emptyNameTooltipErrorMessage');
          this.setState({ nameTooltipMessage });
      }

    this.setState({ name, isNameInvalid });
  };

  private _onNameInputMouseEnterHandler = (): void => {
    if (this.state.isNameInvalid) {
      const isNameTooltipOpen: boolean = true;
      this.setState({ isNameTooltipOpen });
    }
  };

  private _onNameInputMouseLeaveHandler = (): void => {
    if (this.state.isNameInvalid) {
      const isNameTooltipOpen: boolean = false;
      this.setState({ isNameTooltipOpen });
    }
  };

  private _onTypeChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const opType = this._fromStringToType(event.currentTarget.value);
    const type: ChannelType = ChannelType[opType as keyof typeof ChannelType];
    this.setState({ type });
  };

    private _fromStringToType = (opType: string): any => {
        switch (opType) {
            case i18n.t('channels.telegram'):
                return "Telegram";
            case i18n.t('channels.viber'):
                return "Viber";
            case i18n.t('channels.vk'):
                return "Vk";
            case i18n.t('channels.widget'):
                return "Widget";
            case i18n.t('channels.facebook'):
                return "Facebook";
            case i18n.t('channels.weChat'):
                return "WeChat";
            case i18n.t('channels.instagram'):
                return "Instagram";
            case i18n.t('channels.uniformWidget'):
                return "UniformWidget";
            case i18n.t('channels.ednaWhatsApp'):
                return "EdnaWhatsApp";
            case i18n.t('channels.whatsApp'):
                return "WhatsApp";
            case i18n.t('channels.voiceChannel'):
                return "VoiceChannel";
            case i18n.t('channels.devinoWhatsApp'):
                return "DevinoWhatsApp";
        }
    }

  private _onEnabledChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const enabled = event.currentTarget.checked;
    this.setState({ enabled });
  };

  private _onSaveChannelHandler = (
    channelOperators?: IChannelOperator[]
  ): void => {
    if (
      this.state.isNameInvalid || this.state.isTokenInvalid ||
      this.state.name === "" ||
      this.state.token === ""
    ) {
      showErrorToastr(i18n.t("channels.invalidFieldsMessage"));
      return;
    }

    const channel: IChannel = {
      channelId: this.props.channel.channelId,
      name: this.state.name,
      enabled: this.state.enabled,
      started: false,
      modifiedOn: new Date(),
      type: this.state.type,
      setting: this.props.channel.setting,
      token: this.state.token,
      operators: [],
      hasChats: false,
    };

    if (this.props.channel.channelId !== undefined) {
      channel.operators = channelOperators ?? this.props.channel.operators;
    }
    this.props.onSaveChannel?.(channel);
  };

  private _onDeleteChannelHandler = (): void => {
    this.props.onDeleteChannel?.(this.props.channel);
  };

  private _onSaveChannelSettingHandler = (settings: string): void => {
    this.props.onSaveChannelSetting?.(this.props.channel, settings);
  };

  private _onRestartChannelHandler = (): void => {
    if(this.state.enabled){
        this.props.onRestartChannel?.(this.props.channel);
    } else {
        showErrorToastr(i18n.t("channels.restartOnlyEnabledChannel"));
    }
  }
}
