import * as React from "react";
import { IChatItem } from "../~store/models/ChatItem";

import './ChatItem.css';
import { ChannelType } from "src/app/channel/~store/models/enums/ChannelType";

interface IChatItemProps {
    chatItem: IChatItem,
    isSelected: boolean,
    operatorId: number
  }

const ChatItem  = (props: IChatItemProps) => {

    let channel = "channel-type-";
    switch(props.chatItem.channelType)
    {
        case ChannelType.Telegram:
            channel += "telegram"
            break;
        case ChannelType.Viber:
            channel += "viber"
            break;
        case ChannelType.Vk:
            channel += "vk"
            break;
        case ChannelType.Widget:
            channel += "widget"
            break;
        case ChannelType.Facebook:
            channel += "facebook"
            break;
        case ChannelType.WeChat:
            channel += "wechat"
            break;
        case ChannelType.Instagram:
            channel += "instagram"
            break;
        case ChannelType.UniformWidget:
            channel += "uniformwidget"
            break;
        case ChannelType.EdnaWhatsApp:
            channel += "ednawhatsapp"
            break;
        case ChannelType.WhatsApp:
            channel += "whatsapp"
            break;
        case ChannelType.DevinoWhatsApp:
            channel += "devinowhatsapp"
            break;
    }
    return (
        <div className={(props.isSelected ? "chat-panel__selected-chat " : "") + "chat-item " + channel}>
            <img className="chat-panel__chat-item__image"></img>
            <div className={"chat-panel__chat-item__count " + (props.operatorId === props.chatItem.operatorId ?
                                "chat-panel__chat-item__chat-owner" : 
                                "chat-panel__chat-item__invited")}>{props.chatItem.notAnsweredClientMessagesCount}</div>



            <div className="chat-panel__chat-item__chat-id">#{props.chatItem.chatId}</div>
        </div>
    );
}


export default ChatItem;