import {AppThunkAction} from "src/~store/models/AppThunkAction";
import {ITopic} from "../models/Topic";
import {ITopicPair} from "../models/TopicPair";
import {TopicPairStatus} from "../models/enums/TopicPairStatus";
import {MessageBoxType} from "../models/enums/MessageBoxType";
import {ISearchStatsDto} from "../models/SearchStats";
import {showErrorToastr, showSuccessToastr} from "../../../shared/helpers/toastr-helper/ToastrHelper";
import {
    CreateTopicAction,
    CancelCreateTopicAction,
    DeleteTopicAction,
    SaveTopicAction,
    ExportPairsAction,
    PublishQuestionsAction,
    ChangeSelectedTopicPairStatusAction,
    SaveTopicPairAction,
    CreateTopicPairAction,
    CancelCreateTopicPairAction,
    DeleteTopicPairAction,
    ToggleTransferQuestionPopupVisibilityAction,
    SetTransferQuestionPopupDataAction,
    ClearTransferQuestionPopupDataAction,
    TransferQuestionToTopicPairAction,
    ChangeTopicPairStatusAction,
    GetAllTopicsAction,
    UpdateTargetTopicPairIdAction,
    ToggleMessageBoxVisibilityAction,
    SetMessageBoxDataAction,
    GetTopicByExternalIdAction,
    ToggleTopicWidgetVisibilityAction,
    AddQuestionAction,
    GetAnswersAction,
    ClearTopicWidgetMessagesAction,
    ImportPairsAction,
    GetTopicPairsByTopicNameWithFilterAction,
    GetTopicPairsCategoriesByTopicNameAction,
    SetFilterTagsAction,
    GetQuestionsByTopicNameAction,
    ToggleTopicSortingByIdAction,
    ToggleTopicSortingByNameAction,
    GenerateQuestionsAction,
    SetIsPublishingQuestionsStatusAction
} from "./interfaces";
import {
    GET_ALL_TOPICS_START,
    GET_ALL_TOPICS_SUCCESS,
    GET_ALL_TOPICS_ERROR,
    EXPORT_PAIRS_START,
    EXPORT_PAIRS_SUCCESS,
    EXPORT_PAIRS_ERROR,
    PUBLISH_QUESTIONS_START,
    PUBLISH_QUESTIONS_SUCCESS,
    PUBLISH_QUESTIONS_ERROR,
    SAVE_TOPIC_START,
    SAVE_TOPIC_SUCCESS,
    SAVE_TOPIC_ERROR,
    CREATE_TOPIC,
    CANCEL_CREATE_TOPIC,
    CHANGE_SELECTED_TOPIC_PAIR_STATUS,
    SAVE_TOPIC_PAIR_START,
    SAVE_TOPIC_PAIR_SUCCESS,
    SAVE_TOPIC_PAIR_ERROR,
    CREATE_TOPIC_PAIR,
    CANCEL_CREATE_TOPIC_PAIR,
    DELETE_TOPIC_PAIR_START,
    DELETE_TOPIC_PAIR_SUCCESS,
    DELETE_TOPIC_PAIR_ERROR,
    TOGGLE_TRANSFER_QUESTION_POPUP_VISIBILITY,
    SET_TRANSFER_QUESTION_POPUP_DATA,
    CLEAR_TRANSFER_QUESTION_POPUP_DATA,
    TRANSFER_QUESTION_TO_TOPIC_PAIR_START,
    TRANSFER_QUESTION_TO_TOPIC_PAIR_SUCCESS,
    TRANSFER_QUESTION_TO_TOPIC_PAIR_ERROR,
    CHANGE_TOPIC_PAIR_STATUS_START,
    CHANGE_TOPIC_PAIR_STATUS_SUCCESS,
    CHANGE_TOPIC_PAIR_STATUS_ERROR,
    UPDATE_TARGET_TOPIC_PAIR_ID,
    TOGGLE_MESSAGE_BOX_VISIBILITY,
    SET_MESSAGE_BOX_DATA,
    GET_TOPIC_BY_EXTERNAL_ID_START,
    GET_TOPIC_BY_EXTERNAL_ID_SUCCESS,
    GET_TOPIC_BY_EXTERNAL_ID_ERROR,
    TOGGLE_TOPIC_WIDGET_VISIBILITY,
    ADD_QUESTION,
    GET_ANSWERS_START,
    GET_ANSWERS_SUCCESS,
    GET_ANSWERS_ERROR,
    CLEAR_TOPIC_WIDGET_MESSAGES,
    IMPORT_PAIRS_START,
    IMPORT_PAIRS_SUCCESS,
    IMPORT_PAIRS_ERROR,
    GET_TOPIC_PAIRS_BY_TOPIC_NAME_WITH_FILTER_SUCCESS,
    GET_TOPIC_PAIR_CATEGORIES_SUCCESS,
    SET_FILTER_TAGS_SUCCESS,
    GET_QUESTIONS_BY_TOPIC_NAME_SUCCESS,
    TOGGLE_TOPIC_SORTING_BY_ID,
    TOGGLE_TOPIC_SORTING_BY_NAME,
    GENERATE_QUESTIONS_START,
    GENERATE_QUESTIONS_SUCCESS,
    GENERATE_QUESTIONS_ERROR,
    SET_IS_PUBLISHING_QUESTIONS_STATUS
} from "./types";
import {
    getAllTopicsAsync,
    deleteTopicAsync,
    saveTopicAsync,
    saveTopicPairAsync,
    deleteTopicPairAsync,
    exportPairsAsync,
    publishQuestionsAsync,
    transferQuestionToTopicPairAsync,
    changeTopicPairStatusAsync,
    getTopicByExternalIdAsync,
    getAnswersAsync,
    importPairsAsync,
    getTopicByTopicNameWithFilterAsync,
    getTopicPairCategoriesByTopicNameAsync,
    saveSynonymsAsync,
    getSynonymsAsync,
    tryImportTopicAsync,
    logOperatorAnswerAsync,
    getQuestionsByTopicNameAsync,
    generateQuestionsAsync
} from "../../~api/actions";
import { ISearchingTag, TagName, TagType } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import i18n from "src/app/shared/localization/i18n";
import { saveTagsAsync } from "src/app/chats/~api/actions";
import { ITagModel } from "src/app/chat/specify-chat-close-reason-dialog/~store/models/TagModel";
import { TopicPairContentType } from "../models/enums/TopicPairContentType";
import { TOPIC_PAIRS_ON_PAGE } from "./constants";

// topic
export const getAllTopics = (): AppThunkAction<GetAllTopicsAction> => async dispatch => {
    dispatch({type: GET_ALL_TOPICS_START});

    const result = await getAllTopicsAsync();
    if (result.isSuccess) {
        const topics = result.value ? result.value : [];
        dispatch({type: GET_ALL_TOPICS_SUCCESS, payload: {topics}});
    } else {
        dispatch({type: GET_ALL_TOPICS_ERROR});
    }
};

export const setFilterTags = (tags: ISearchingTag[]): AppThunkAction<SetFilterTagsAction> => async dispatch => {
    dispatch({type: SET_FILTER_TAGS_SUCCESS, payload: {filterTags: tags}});
};

export const getTopicByExternalId = (topicExternalId: string, selectedPage: number = 1): AppThunkAction<GetTopicByExternalIdAction> => async (dispatch, getState) => {
    dispatch({type: GET_TOPIC_BY_EXTERNAL_ID_START});

    const { selectedTopicPairStatus } = getState().knowledgeBaseState.topicEditor;

    const tags = getSearchingTagWithTopicPairStatus(getState().knowledgeBaseState.filterTags, selectedTopicPairStatus);

    const result = await getTopicByExternalIdAsync(topicExternalId, tags, (selectedPage - 1) * TOPIC_PAIRS_ON_PAGE, TOPIC_PAIRS_ON_PAGE);
    if (result.isSuccess) {
        const topic = result.value ? result.value : ({} as ITopic);
        dispatch({type: GET_TOPIC_BY_EXTERNAL_ID_SUCCESS, payload: {topic}});
    } else {
        dispatch({type: GET_TOPIC_BY_EXTERNAL_ID_ERROR});
    }
};

export const createTopic = (): AppThunkAction<CreateTopicAction> => async dispatch => {
    dispatch({type: CREATE_TOPIC});
};

export const cancelCreateTopic = (): AppThunkAction<CancelCreateTopicAction> => async dispatch => {
    dispatch({type: CANCEL_CREATE_TOPIC});
};

export const deleteTopic = (topicExternalId: string): AppThunkAction<DeleteTopicAction | GetAllTopicsAction> => async (dispatch, getState) => {
    const result = await deleteTopicAsync(topicExternalId);
    if (result.isSuccess) {
        showSuccessToastr(i18n.t('nlp.deleteTopicSuccessMessage'));
    } else {
        showErrorToastr(i18n.t('nlp.deleteTopicErrorMessage'));
    }

    getAllTopics()(dispatch, getState);
};

export const saveTopic = (topicExternalId: string, topicName: string, parametersJson: string): AppThunkAction<SaveTopicAction | GetAllTopicsAction> => async (
    dispatch,
    getState
) => {
    dispatch({type: SAVE_TOPIC_START});

    const result = await saveTopicAsync(topicExternalId, topicName, parametersJson);
    if (result.isSuccess) {
        showSuccessToastr(i18n.t('nlp.saveTopicSuccessMessage'));
        dispatch({type: SAVE_TOPIC_SUCCESS, payload: {topicExternalId, topicName, parametersJson}});
        if(result.value) {
            tryImportTopicAsync(result.value.topicExternalId);
        }
    } else {
        const errorText = JSON?.parse(result.errorText ?? "null");
        if (errorText.errorCode === "nlp.nlpTopicAlreadyExistsError") {
            showErrorToastr(i18n.t('nlp.nlpTopicAlreadyExistsError', {topicName: errorText.data.topicName}));
            dispatch({ type: CANCEL_CREATE_TOPIC });
        } else {
            showErrorToastr(i18n.t('nlp.saveTopicErrorMessage'));
            dispatch({type: SAVE_TOPIC_ERROR});
        }
    }

    getAllTopics()(dispatch, getState);
};

export const exportPairs = (topicExternalId: string): AppThunkAction<ExportPairsAction> => async dispatch => {
    dispatch({ type: EXPORT_PAIRS_START });
    const result = await exportPairsAsync(topicExternalId);
    
    if (result.isSuccess) {
        dispatch({ type: EXPORT_PAIRS_SUCCESS});
        
    } else {
        showErrorToastr(i18n.t('nlp.exportPairsErrorMessage'));
        dispatch({ type: EXPORT_PAIRS_ERROR });
    }
};

export const importPairs = (topicExternalId: string, file: File): AppThunkAction<ImportPairsAction> => async dispatch => {
    dispatch({ type: IMPORT_PAIRS_START });
    const result = await importPairsAsync(topicExternalId, file);

    if(result.isSuccess) {
        showSuccessToastr(i18n.t('nlp.importPairsSuccessMessage'));
        dispatch({ type: IMPORT_PAIRS_SUCCESS });
    } else {
        showErrorToastr(i18n.t('nlp.importPairsErrorMessage'));
        dispatch({ type: IMPORT_PAIRS_ERROR });
    }
}

export const publishQuestions = (topicExternalId: string): AppThunkAction<PublishQuestionsAction> => async dispatch => {
    dispatch({type: PUBLISH_QUESTIONS_START});
    const result = await publishQuestionsAsync(topicExternalId);
    if (result.isSuccess) {
        showSuccessToastr(i18n.t('nlp.publishQuestionsSuccessMessage'));
        dispatch({type: PUBLISH_QUESTIONS_SUCCESS});
    } else {
        showErrorToastr(i18n.t('nlp.publishQuestionsErrorMessage'));
        dispatch({type: PUBLISH_QUESTIONS_ERROR});
    }
};

export const setIsPublishingQuestionsStatus = (status: boolean): AppThunkAction<SetIsPublishingQuestionsStatusAction> => async dispatch => {
    dispatch({type: SET_IS_PUBLISHING_QUESTIONS_STATUS, payload: {status: status}});
}

// topic pairs
export const changeSelectedTopicPairStatus = (topicPairStatus: TopicPairStatus): AppThunkAction<ChangeSelectedTopicPairStatusAction> => dispatch => {
    dispatch({type: CHANGE_SELECTED_TOPIC_PAIR_STATUS, payload: {topicPairStatus}});
};

export const changeTopicPairStatus = (
    topicExternalId: string,
    topicPairId: number,
    topicPairStatus: TopicPairStatus
): AppThunkAction<ChangeTopicPairStatusAction | GetTopicByExternalIdAction> => async (dispatch, getState) => {
    dispatch({type: CHANGE_TOPIC_PAIR_STATUS_START});
    const result = await changeTopicPairStatusAsync(topicExternalId, topicPairId, topicPairStatus);
    if (result.isSuccess) {
        showSuccessToastr(i18n.t('nlp.changeTopicPairStatusSuccessMessage'));
        dispatch({type: CHANGE_TOPIC_PAIR_STATUS_SUCCESS, payload: {topicPairId, topicPairStatus}});
    } else {
        showErrorToastr(i18n.t('nlp.changeTopicPairStatusErrorMessage'));
        dispatch({type: CHANGE_TOPIC_PAIR_STATUS_ERROR});
    }

    getTopicByExternalId(topicExternalId)(dispatch, getState);
};

export const saveTopicPair = (
    topicExternalId: string,
    topicPair: ITopicPair,
    selectedPage: number,
): AppThunkAction<SaveTopicPairAction | GetTopicByExternalIdAction> => async (dispatch, getState) => {
    dispatch({type: SAVE_TOPIC_PAIR_START});
    const result = await saveTopicPairAsync(topicExternalId, topicPair);
    if (result.isSuccess) {
        showSuccessToastr(i18n.t('nlp.saveTopicPairSuccessMessage'));
        dispatch({type: SAVE_TOPIC_PAIR_SUCCESS, payload: {topicExternalId, topicPair}});
    } else {
        const errorText = JSON?.parse(result.errorText ?? "null");
        if (errorText.errorCode === "nlp.questionWithOnlyStopWordsError")
            showErrorToastr(i18n.t(errorText.errorCode, { question: errorText.data.question }));
        else

            showErrorToastr(i18n.t('nlp.saveTopicPairErrorMessage'));
        dispatch({ type: SAVE_TOPIC_PAIR_ERROR });

        return;
    }

    getTopicByExternalId(topicExternalId, selectedPage)(dispatch, getState);
};

export const deleteTopicPair =
  (
    topicExternalId: string,
    topicPairId: number,
    selectedPage: number
  ): AppThunkAction<DeleteTopicPairAction | GetTopicByExternalIdAction> =>
  async (dispatch, getState) => {
    dispatch({ type: DELETE_TOPIC_PAIR_START });
    const result = await deleteTopicPairAsync(topicExternalId, topicPairId);
    if (result.isSuccess) {
      showSuccessToastr(i18n.t("nlp.deleteTopicPairSuccessMessage"));
      dispatch({ type: DELETE_TOPIC_PAIR_SUCCESS, payload: { topicPairId } });
    } else {
      showErrorToastr(i18n.t("nlp.deleteTopicPairErrorMessage"));
      dispatch({ type: DELETE_TOPIC_PAIR_ERROR });
    }

    getTopicByExternalId(topicExternalId, selectedPage)(dispatch, getState);
  };

export const createTopicPair = (topicPairStatus: TopicPairStatus, defaultContentType: TopicPairContentType): AppThunkAction<CreateTopicPairAction> => async dispatch => {
    dispatch({type: CREATE_TOPIC_PAIR, payload: {topicPairStatus, defaultContentType}});
};

export const cancelCreateTopicPair = (): AppThunkAction<CancelCreateTopicPairAction> => async dispatch => {
    dispatch({type: CANCEL_CREATE_TOPIC_PAIR});
};

// transfer question popup
export const updateTargetTopicPairId = (targetTopicId: number): AppThunkAction<UpdateTargetTopicPairIdAction> => dispatch => {
    dispatch({type: UPDATE_TARGET_TOPIC_PAIR_ID, payload: {targetTopicId}});
};

export const toggleTransferQuestionPopupVisibility = (): AppThunkAction<ToggleTransferQuestionPopupVisibilityAction> => dispatch => {
    dispatch({type: TOGGLE_TRANSFER_QUESTION_POPUP_VISIBILITY});
};

export const transferQuestionToTopicPair = (
    topicExternalId: string,
    actualTopicPairId: number,
    expectedTopicPairId: number,
    questionId: number
): AppThunkAction<TransferQuestionToTopicPairAction | GetTopicByExternalIdAction> => async (dispatch, getState) => {
    dispatch({type: TRANSFER_QUESTION_TO_TOPIC_PAIR_START});
    const result = await transferQuestionToTopicPairAsync(topicExternalId, actualTopicPairId, expectedTopicPairId, questionId);
    if (result.isSuccess) {
        showSuccessToastr(i18n.t('nlp.transferQuestionToTopicPairSuccessMessage'));
        dispatch({type: TRANSFER_QUESTION_TO_TOPIC_PAIR_SUCCESS, payload: {topicExternalId, actualTopicPairId, expectedTopicPairId, questionId}});
    } else {
        showErrorToastr(i18n.t('nlp.transferQuestionToTopicPairErrorMessage'));
        dispatch({type: TRANSFER_QUESTION_TO_TOPIC_PAIR_ERROR});
    }

    getTopicByExternalId(topicExternalId)(dispatch, getState);
};

export const setTransferQuestionPopupData = (
    topicExternalId: string,
    topicPairId: number,
    questionId: number
): AppThunkAction<SetTransferQuestionPopupDataAction> => dispatch => {
    dispatch({type: SET_TRANSFER_QUESTION_POPUP_DATA, payload: {topicExternalId, topicPairId, questionId}});
};

export const clearTransferQuestionPopupData = (): AppThunkAction<ClearTransferQuestionPopupDataAction> => dispatch => {
    dispatch({type: CLEAR_TRANSFER_QUESTION_POPUP_DATA});
};

// message box
export const toggleMessageBoxVisibility = (): AppThunkAction<ToggleMessageBoxVisibilityAction> => dispatch => {
    dispatch({type: TOGGLE_MESSAGE_BOX_VISIBILITY});
};

export const setMessageBoxData = (data: {
    title: string;
    message: string;
    className?: string;
    type?: MessageBoxType;
    onClose?: () => void;
    onConfirm?: () => void;
    onReject?: () => void;
}): AppThunkAction<SetMessageBoxDataAction> => dispatch => {
    dispatch({
        type: SET_MESSAGE_BOX_DATA,
        payload: {...data}
    });
};

// topic widget
export const toggleTopicWidgetVisibility = (): AppThunkAction<ToggleTopicWidgetVisibilityAction> => dispatch => {
    dispatch({type: TOGGLE_TOPIC_WIDGET_VISIBILITY});
};

export const addQuestion = (questionText: string): AppThunkAction<AddQuestionAction> => dispatch => {
    dispatch({type: ADD_QUESTION, payload: {questionText}});
};

export const getAnswers = (topicName: string, questionText: string): AppThunkAction<GetAnswersAction> => async dispatch => {
    dispatch({type: GET_ANSWERS_START});

    const result = await getAnswersAsync(topicName, questionText);
    if (result.isSuccess) {
        const answers = result.value?.answers ? result.value.answers : [];
        const questionTextFromApi = result.value ? result.value.questionText : "";
        dispatch({type: GET_ANSWERS_SUCCESS, payload: {questionText: questionTextFromApi, answers}});
    } else {
        dispatch({type: GET_ANSWERS_ERROR});

        const errorText = JSON.parse(result.errorText ?? "null");

        if (errorText) {
            showErrorToastr(i18n.t(errorText.errorCode, errorText.data));
            return;
        }
    }
};

export const clearTopicWidgetMessages = (): AppThunkAction<ClearTopicWidgetMessagesAction> => dispatch => {
    dispatch({type: CLEAR_TOPIC_WIDGET_MESSAGES});
};


export const getTopicPairsByTopicNameWithFilter = (topicName: string, tags: ISearchingTag[], selectedPage: number): AppThunkAction<GetTopicPairsByTopicNameWithFilterAction> => async (dispatch) => {
    const result = await getTopicByTopicNameWithFilterAsync(topicName, (selectedPage - 1) * TOPIC_PAIRS_ON_PAGE, TOPIC_PAIRS_ON_PAGE, tags);

    if (result.isSuccess) {
        const topic = result.value ?? {} as ITopic;
        dispatch({type: GET_TOPIC_PAIRS_BY_TOPIC_NAME_WITH_FILTER_SUCCESS, payload: {topic}});
    }
    else {
        showErrorToastr(i18n.t('error.error'));
    }
};

export const getTopicPairCategoriesByTopicName = (topicName: string): AppThunkAction<GetTopicPairsCategoriesByTopicNameAction> => async dispatch => {
    const result = await getTopicPairCategoriesByTopicNameAsync(topicName);

    if (result.isSuccess && result.value) {
        const {categories, sources} = result.value;

        dispatch({type: GET_TOPIC_PAIR_CATEGORIES_SUCCESS, payload: {topicPairCategories: categories || [], topicPairSources: sources || []}});
    }
    else {
        dispatch({type: GET_TOPIC_PAIR_CATEGORIES_SUCCESS, payload: {topicPairCategories: [], topicPairSources: []}});
        showErrorToastr(i18n.t('error.error'));
    }
};

export const getQuestionsByTopicName = (topicName: string, questionText: string, pairId: string): AppThunkAction<GetQuestionsByTopicNameAction> => async dispatch => {
    if (topicName) {
        const result = await getQuestionsByTopicNameAsync(topicName, questionText, pairId);

        if (result.isSuccess && result.value) {
            const val = result.value;

            dispatch({ type: GET_QUESTIONS_BY_TOPIC_NAME_SUCCESS, payload: { questions: val } });
        }
        else {
            dispatch({ type: GET_QUESTIONS_BY_TOPIC_NAME_SUCCESS, payload: { questions: [] } });
            showErrorToastr(i18n.t('error.error'));
        }
    }
}

export const getSynonyms = async (topicExternalId: string, onSuccess: (synonymsText: string) => void): Promise<void>  => {
    const result = await getSynonymsAsync(topicExternalId);

    if (result.isSuccess) {
        onSuccess(result.value?.synonymsText || '');
    }
    else {
        showErrorToastr(i18n.t('error.error'));
    }
};

export const saveSynonyms = async (topicExternalId: string, synonymsText: string, onSuccess: () => void): Promise<void>  => {
    const result = await saveSynonymsAsync(topicExternalId, synonymsText);

    if (result.isSuccess) {
        showSuccessToastr(i18n.t('nlp.saveTopicPairSuccessMessage'));
        onSuccess();
    }
    else {
        try {
            const errorText = JSON.parse(result.errorText ?? "null");

            if(errorText) {
                showErrorToastr(i18n.t(errorText.errorCode, errorText.data));
                return;
            }
        }
        catch(ex) {}
        
        showErrorToastr(i18n.t('error.error'));
    }
};

export const saveTopicPairUsedTag = (topicPair: ITopicPair, isOperatorTemplate: boolean = false): AppThunkAction<void>  => async (_, getState) => {
    const {selectedChatId, messages} = getState().chatItemsState;
    const group = messages[messages.length - 1].messageId.toString();
    
    const subject = topicPair.subject;
    const headliner = topicPair.questions.find((q) => q.isDefault)?.text || "";
    const trigger = topicPair.triggerName;
    
    let tags : ITagModel[] = [];
    
    let subjectTag = TryCreateTag(selectedChatId, `Subject${isOperatorTemplate ? "Template" : ""}OperatorUsed`, subject, group);
    let headlinerTag = TryCreateTag(selectedChatId, `Headliner${isOperatorTemplate ? "Template" : ""}OperatorUsed`, headliner, group);
    let triggerTag = TryCreateTag(selectedChatId, `Trigger${isOperatorTemplate ? "Template" : ""}OperatorUsed`, trigger, group);

    if(subjectTag !== null) tags.push(subjectTag);
    if(headlinerTag !== null) tags.push(headlinerTag);
    if(triggerTag !== null) tags.push(triggerTag);

    if(selectedChatId && tags.length > 0) {
            await saveTagsAsync(tags);
    }
};

const TryCreateTag = (chatId: number, name: string, value: string, group: string | null): ITagModel | null => {
    if(name !== null && name !== undefined && name.trim() !== ""
        && value !== null && value !== undefined && value.trim() !== "") {
        
            return {
                chatId: chatId,
                name: name,
                value: value,
                group: group 
            } as ITagModel;
    }

    return null;
}

export const logOperatorAnswer = (topicPair: ITopicPair, topicName: string | undefined, currentTags: ISearchingTag[] | undefined): AppThunkAction<void> => async (_, getState) => {
    const { selectedChat, operatorId } = getState().chatItemsState;

    if (selectedChat?.operatorId !== operatorId && !selectedChat?.invitedOperatorsId.includes(operatorId)) {
        showErrorToastr(i18n.t('nlp.errorSendNLPSearchWhenChatNotAssigned'));
        return;
    }

    const userInStorage = sessionStorage.getItem("user");

    var searchStatsDto = {
        TopicName: topicName,
        AnswerId: topicPair.answer?.answerId,
        ChannelId: selectedChat?.channelId,
        ChatId: selectedChat?.chatId,
        OperatorId: selectedChat?.operatorId,
        CustomerId : userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId,
        TriggerName: topicPair.triggerName,
        SearchText: currentTags?.find(t => t.tagType === TagType.ClosingTag && t.tagName === TagName.Phrase)?.value,
        Subject: topicPair.subject,
        Headliner: topicPair.questions.find((q) => q.isDefault)?.text || "",
        IsOperator: true
    } as ISearchStatsDto;

    await logOperatorAnswerAsync(searchStatsDto);

}

const getSearchingTagWithTopicPairStatus = (tags: ISearchingTag[], status: TopicPairStatus): ISearchingTag[]  => {
    const openingTag: ISearchingTag = {
        id: tags.length,
        value: 'TopicPairStatus',
        tagType: TagType.OpeningTag,
        tagName: TagName.Status,
      };
      const operationTag: ISearchingTag = {
        id: tags.length + 1,
        value: "=",
        tagType: TagType.OperationTag,
      };
      const closingTag: ISearchingTag = {
        id: tags.length + 2,
        value: TopicPairStatus[status],
        tagType: TagType.ClosingTag,
        tagName: TagName.Status,
      };

      return tags.concat([openingTag, operationTag, closingTag]);
}

export const toggleTopicByIdSorting = (): AppThunkAction<ToggleTopicSortingByIdAction> => async dispatch => {
    dispatch({ type: TOGGLE_TOPIC_SORTING_BY_ID });
}

export const toggleTopicByNameSorting = (): AppThunkAction<ToggleTopicSortingByNameAction> => async dispatch => {
    dispatch({ type: TOGGLE_TOPIC_SORTING_BY_NAME });
}

export const generateQuestions = (topicExternalId: string): AppThunkAction<GenerateQuestionsAction> => async dispatch => {
    dispatch({type: GENERATE_QUESTIONS_START});
    const result = await generateQuestionsAsync(topicExternalId);
    if (result.isSuccess) {
        showSuccessToastr(i18n.t('nlp.generateQuestionsSuccessMessage'));
        dispatch({type: GENERATE_QUESTIONS_SUCCESS});
    } else {
        showErrorToastr(i18n.t('nlp.generateQuestionsErrorMessage'));
        dispatch({type: GENERATE_QUESTIONS_ERROR});
    }
}