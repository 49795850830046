import * as React from "react";
import {Guid} from "guid-typescript";

import {ITopicPair} from "../../~store/models/TopicPair";
import {ITopicPairItem, IQuestionItem, IAnswerItem } from "./~types/TopicPairItem";
import {TopicPairStatus} from "../../~store/models/enums/TopicPairStatus";
import {ITopicPairsItemProps} from "./~types/TopicPairsItemProps";
import {ITopicPairsItemState} from "./~types/TopicPairsItemState";

import {Button, Input, Label, ButtonGroup} from "reactstrap";
import ScalableTextInput from "../../../shared/components/scalable-text-input/ScalableTextInput";
import NlpAnswerTextInput from "../../../shared/components/nlp-answer-text-inpit/NlpAnswerTextInput"
import { stringToTopicPairContentType, TopicPairContentType, topicPairContentTypeToString } from "../../~store/models/enums/TopicPairContentType";

import "./TopicPairsItem.css";
import "src/app/setting/settings-main/settings-list/setting-item/SettingItem.css"
import { createNlpButton, INlpButton, NlpButtonType } from "../../~store/models/Answer";
import { ModalWindow } from "src/app/shared/components/modal-window/ModalWindow";
import i18n from "src/app/shared/localization/i18n";
import SettingsItem from "src/app/setting/settings-main/settings-list/setting-item/SettingItem";
import { ISetting } from "src/app/setting/~store/models/setting";

class TopicPairsItem extends React.Component<ITopicPairsItemProps, ITopicPairsItemState> {
    public readonly state: Readonly<ITopicPairsItemState> = {
        editedTopicPair: {} as ITopicPairItem,
        answerTextVariant: 1,
        currentAnswerText: "",
        editingButton: undefined,
        editingButtonName: undefined,
        topicPairContentType: this.props.topicPairContentType
    };

    private inpRef: React.RefObject<ScalableTextInput>;
    private headerEditDynamicButtonText: string | undefined;
    private typeEditDynamicButtonText: string | undefined;

    constructor(props: ITopicPairsItemProps) {
        super(props);
        this.inpRef = React.createRef();
    }

    public componentWillMount() {
        this._setEditedItemByTopicPair(this.props.innerTopicPair);
    }

    public componentWillReceiveProps(nextProps: ITopicPairsItemProps) {
        this._setEditedItemByTopicPair(nextProps.innerTopicPair);
    }

    public componentDidMount() {
        this.setCurrentAnswerText();
        if (this.props.innerTopicPair.topicPairId === 0) {
            this.setState({
                editedTopicPair: {
                    ...this.state.editedTopicPair,
                    contentType: this.state.topicPairContentType
                }
            })
        }
    }

    public setCurrentAnswerText() {
        const {editedTopicPair} = this.state;
        const answer = this._getEditedTopicPairAnswer();
        const buttons = answer?.buttons;
        this.setState({editedTopicPair: {...editedTopicPair, answer: {...answer, buttons: buttons ? [...buttons] : answer.buttons}}, editingButtonName: undefined, editingButton: undefined})
        this.setState({currentAnswerText: editedTopicPair.answer ? editedTopicPair.answer.text : ""}, () => {
            this.updateAnswerInput();});
    }

    public updateAnswerInput() {
        this.inpRef.current?.resize();
    }


    public render() {
        const {editedTopicPair} = this.state;
        const isHasChanges = this._checkIfHasChanges();
        const isTopicPairInPendingStatus = editedTopicPair.status === TopicPairStatus.Pending;
        const questionContainerClassName = isTopicPairInPendingStatus ? "col s12" : "col s6";
        const isAllQuestionFieldsAreFilled = this._checkIfAllQuestionFieldsAreFilled();

        return (
            <section className="topic-pairs-item" id={editedTopicPair.topicPairId.toString()}>
                <div className="topic-pairs-item-header">
                    <div className="topic-pairs-item-header__content-grow topic-pairs-item-header__identifier">
                        <div className="topic-pairs-item-header__topic-pair-id">{editedTopicPair.topicPairId}</div>
                        <div className="topic-pairs-item-header__trigger_name">
                            <Input onChange={this._onChangeTriggerNameHandler} value={editedTopicPair.triggerName || ""} placeholder={i18n.t('nlp.triggerName')} type="text" className="input__trigger-name" />
                        </div>
                        <div className="topic-pairs-item-header__category">
                            <input value={editedTopicPair.category} onChange={this._onCategoryChanged} placeholder={i18n.t('chat.subject')} type="text" list="categories" />
                            <datalist id="categories">
                                {this.props.topicPairCategories.map((item, index) => {
                                    return (
                                        <option key={index}>{item}</option>
                                    );
                                })}
                            </datalist>
                        </div>
                    </div>
                    <div className="topic-pairs-item-header__content">
                        {this._getTopicPairContentTypeDropdown()}
                        <SettingsItem
                            setting= { {value: JSON.stringify(this.props.innerTopicPair.answer?.parametersJson)} as ISetting }
                            onSettingSave={(setting) => this._onSaveButtonClick(setting.value)}
                        />
                        {isHasChanges && (
                            <React.Fragment>
                                <Button
                                    className="topic-pairs-item__button topic-pairs-item__button-save"
                                    onClick={this._onSaveChangesHandler}
                                    disabled={!isAllQuestionFieldsAreFilled}>
                                    <i className="material-icons">save</i>
                                </Button>
                                <Button className="topic-pairs-item__button topic-pairs-item__button-discard" onClick={this._onDiscardChangesHandler}>
                                    <i className="material-icons">replay</i>
                                </Button>
                            </React.Fragment>
                        )}

                        {!isHasChanges && (
                            <React.Fragment>
                                {this._getChangeStatusButton()}
                                <Button className="topic-pairs-item__button topic-pairs-item__button-delete" onClick={this._onDeleteTopicPairHandler}>
                                    <i className="material-icons">delete_forever</i>
                                </Button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="topic-pairs-item-content row">
                    <div className={questionContainerClassName + " topic-pairs-item__question"}>
                        {!isTopicPairInPendingStatus && (
                            <Button className="topic-pairs-item__button-create" onClick={this._onCreateQuestionHandler}>
                                <i className="material-icons">add</i>
                            </Button>
                        )}
                        {this._getQuestionInputControls(isTopicPairInPendingStatus, isHasChanges)}
                    </div>

                    {!isTopicPairInPendingStatus && (
                        <div className="col s6">
                            <div className="topic-pairs-item__variant-buttons">
                                <Button name = "variant-1" 
                                        onClick={this._onChangeAnswerTextVariantHandler}>
                                    <span className={this.state.answerTextVariant === 1 ? "topic-pairs-item__button-variant-answer_checked" : ""}>{i18n.t('common.answer') + ' 1'}</span> 
                                </Button>
                                <Button name = "variant-2"  
                                        onClick={this._onChangeAnswerTextVariantHandler}>
                                    <span className={this.state.answerTextVariant === 2 ? "topic-pairs-item__button-variant-answer_checked" : ""}>{i18n.t('common.answer') + ' 2'}</span> 
                                </Button>
                                <Button name = "variant-3" 
                                        onClick={this._onChangeAnswerTextVariantHandler}>
                                    <span className={this.state.answerTextVariant === 3 ? "topic-pairs-item__button-variant-answer_checked" : ""}>{i18n.t('common.answer') + ' 3'}</span>
                                </Button>
                            </div>
                            <NlpAnswerTextInput value={this.state.currentAnswerText} placeholder="No answer exists" onChange={this._onChangeAnswerTextHandler}/>
                            <span>{this._mapButtons(this.state.editedTopicPair.answer?.buttons)}</span>
                        </div>
                    )}
                </div>
                <ModalWindow className="" isOpen={this.state.editingButton !== undefined} header={this._getEditingButtonHeader()} body={this._getEditingButtonBody()} footer={this._getEditingButtonFooter()}/>
            </section>
        );
    }

    private _onChangeTriggerNameHandler = (event: React.FormEvent<HTMLInputElement>): void =>{
        const {editedTopicPair} = this.state;
        const triggerName = event.currentTarget.value;
        this.setState({editedTopicPair: {...editedTopicPair, triggerName}});
    }

    private _onCategoryChanged = (event: React.FormEvent<HTMLInputElement>): void =>{
        const {editedTopicPair} = this.state;
        const category = event.currentTarget.value;
        this.setState({editedTopicPair: {...editedTopicPair, category}})
    }

    private _onChangeAnswerTextVariantHandler = (event: React.FormEvent<HTMLInputElement>): void =>{
        let answerTextVariant = 1;
        let currentAnswerText = "";
        switch (event.currentTarget.name)
        {
            case "variant-1":
                answerTextVariant = 1;
                currentAnswerText = this.state.editedTopicPair.answer ? this.state.editedTopicPair.answer.text : "";
                break;
            case "variant-2":
                answerTextVariant = 2;
                currentAnswerText = this.state.editedTopicPair.answer ? this.state.editedTopicPair.answer.alternativeText1 : "";
                break;
            case "variant-3":
                answerTextVariant = 3;
                currentAnswerText = this.state.editedTopicPair.answer ? this.state.editedTopicPair.answer.alternativeText2 : "";
                break;
        }
        this.setState({answerTextVariant});
        this.setState({currentAnswerText: currentAnswerText ? currentAnswerText : ""}, () => {
            this.updateAnswerInput();});
    }

    private _onChangeTopicPairStatusHandler = (topicPairStatus: TopicPairStatus): void => {
        this.props.onChangeTopicPairStatus(this.props.innerTopicPair.topicPairId, topicPairStatus);
    };

    private _onDiscardChangesHandler = (): void => {
        this._setEditedItemByTopicPair(this.props.innerTopicPair);
    };

    private _onSaveChangesHandler = (): void => {
        const topicPair = this._buildTopicPair(this.state.editedTopicPair);
        this.props.onCommitTopicPairChanges(topicPair);
    };

    private _onDeleteTopicPairHandler = (): void => {
        const {
            editedTopicPair: {topicPairId}
        } = this.state;
        this.props.onDeleteTopicPair(topicPairId);
    };

    private _onTransferQuestionHandler = (questionId: number): void => {
        this.props.onTransferTopicPairQuestion(this.state.editedTopicPair.topicPairId, questionId);
    };

    private _onChangeAnswerTextHandler = (event?: string): void => {
        const {editedTopicPair} = this.state;
        const newAnswerText = event ?? "";
        const answer = this._getEditedTopicPairAnswer();
        switch(this.state.answerTextVariant)
        {
            case 1:
                this.setState({editedTopicPair: {...editedTopicPair, answer: {...answer, text: newAnswerText}}});
                break;
            case 2:
                this.setState({editedTopicPair: {...editedTopicPair, answer: {...answer, alternativeText1 : newAnswerText}}});
                break;
            case 3:
                this.setState({editedTopicPair: {...editedTopicPair, answer: {...answer, alternativeText2 : newAnswerText}}});
                break;
            default:
                return;
        }
        this.setState({currentAnswerText: newAnswerText ? newAnswerText : ""});
    };

    private _getEditedTopicPairAnswer = (): IAnswerItem => {
        return this.state.editedTopicPair.answer ? this.state.editedTopicPair.answer : {answerId: 0, text: "", alternativeText1: "", alternativeText2: "", buttons: [], parametersJson: {} };
    }

    private _onChangeQuestionTextHandler = (event: React.FormEvent<HTMLInputElement>, questionGuid: string): void => {
        const {editedTopicPair} = this.state;
        const question = editedTopicPair.questions.find(q => q.questionGuid === questionGuid);
        const newQuestionText = event.currentTarget.value;
        const updatedQuestions = [...editedTopicPair.questions.filter(q => q.questionGuid !== questionGuid), {...question!, text: newQuestionText}];

        this.setState({editedTopicPair: {...editedTopicPair, questions: updatedQuestions}});
    };

    private _onChangeDefaultQuestionHandler = (event: React.FormEvent<HTMLInputElement>, questionGuid: string): void => {
        const {editedTopicPair} = this.state;
        const question = editedTopicPair.questions.find(q => q.questionGuid === questionGuid);
        const updatedQuestions = [
            ...editedTopicPair.questions.filter(q => q.questionGuid !== questionGuid).map(q => ({...q, isDefault: false})),
            {...question!, isDefault: true}
        ];

        this.setState({editedTopicPair: {...editedTopicPair, questions: updatedQuestions}});
    };

    private _onCreateQuestionHandler = (): void => {
        const {editedTopicPair} = this.state;
        const isNoQuestionsExist = editedTopicPair.questions.length === 0;

        const newQuestion = {
            text: "",
            isDefault: isNoQuestionsExist,
            questionId: 0,
            questionGuid: Guid.create().toString(),
            questionIndex: 0
        };

        const questions = editedTopicPair.questions.map(q => ({...q, questionIndex: ++q.questionIndex}));

        this.setState({editedTopicPair: {...editedTopicPair, questions: [...questions, newQuestion]}});
    };

    private _onDeleteQuestionHandler = (questionGuid: string): void => {
        const { editedTopicPair } = this.state;
        const deletedQuestion = editedTopicPair.questions.find(q => q.questionGuid == questionGuid);
        const questions = editedTopicPair.questions.filter(q => q.questionGuid !== questionGuid);
        if (questions.length > 0 && deletedQuestion?.isDefault)
            questions[0].isDefault = true;
        this.setState({editedTopicPair: {...editedTopicPair, questions: [...questions]}});
    };

    private _onNlpButtonDelete = (button: INlpButton) => {
        if (window.confirm(i18n.t('nlp.sureRemoveButton') + button.name + "?")) {
            const answer = this._getEditedTopicPairAnswer();
            this.setState({editedTopicPair: {...this.state.editedTopicPair, answer: {...answer, buttons: answer.buttons.filter(b => b.name !== button.name)}}})
        }
    }

    private _getChangeStatusButton = (): React.ReactElement<{}> => {
        const {editedTopicPair} = this.state;
        switch (editedTopicPair.status) {
            case TopicPairStatus.Active:
                return (
                    <Button
                        className="topic-pairs-item__button topic-pairs-item__button-inactive"
                        onClick={() => this._onChangeTopicPairStatusHandler(TopicPairStatus.Inactive)}>
                        <i className="material-icons">remove</i>
                    </Button>
                );
            case TopicPairStatus.Inactive:
                return (
                    <Button
                        className="topic-pairs-item__button topic-pairs-item__button-active"
                        onClick={() => this._onChangeTopicPairStatusHandler(TopicPairStatus.Active)}>
                        <i className="material-icons">add</i>
                    </Button>
                );
            case TopicPairStatus.Pending:
                return (
                    <Button
                        className="topic-pairs-item__button topic-pairs-item__button-pending"
                        onClick={() => this._onChangeTopicPairStatusHandler(TopicPairStatus.Inactive)}>
                        <i className="material-icons">check</i>
                    </Button>
                );
            default:
                return this._getErrorControl();
        }
    };

    private _getQuestionInputControls = (isTopicPairInPendingStatus: boolean, isHasChanges: boolean): Array<React.ReactElement<{}>> => {
        return this.state.editedTopicPair.questions
            .sort((q1, q2) => q1.questionIndex - q2.questionIndex)
            .map(q => this._getQuestionInputControl(q, isTopicPairInPendingStatus, isHasChanges));
    };

    private _getQuestionInputControl = (
        question: IQuestionItem,
        isTopicPairInPendingStatus: boolean,
        isHasChanges: boolean
    ): React.ReactElement<{}> => {
        return (
            <div key={question.questionGuid} className="topic-pairs-item__question-container">
                <Label className="topic-pairs-item__question-editor" check={true}>
                    <Input
                        type="radio"
                        name={question.questionGuid}
                        checked={question.isDefault}
                        onChange={event => this._onChangeDefaultQuestionHandler(event, question.questionGuid)}
                    />
                    <span>{""}</span>
                    <ScalableTextInput value={question.text} onKeyDown={event => event.keyCode == 13 ? this._onCreateQuestionHandler() : ""} onChange={event => this._onChangeQuestionTextHandler(event, question.questionGuid)} />
                </Label>

                {!isTopicPairInPendingStatus && (
                    <React.Fragment>
                        <ButtonGroup className="topic-pairs-item__question-buttons">
                            {!isHasChanges && (
                                <Button className="topic-pairs-item__button topic-pairs-item__button-move" onClick={() => this._onTransferQuestionHandler(question.questionId)}>
                                    <i className="material-icons">arrow_forward</i>
                                </Button>
                            )}

                            <Button className="topic-pairs-item__button topic-pairs-item__button-delete" onClick={() => this._onDeleteQuestionHandler(question.questionGuid)}>
                                <i className="material-icons">clear</i>
                            </Button>
                        </ButtonGroup>
                    </React.Fragment>
                )}
            </div>
        );
    };

    private _mapButtons = (buttons: INlpButton[] | undefined): React.ReactElement<{}> => {
        const getButtonimage = (type: NlpButtonType): string => {
            switch(type){
                case NlpButtonType.Link: return require("./../../../shared/images/url.png");
                case NlpButtonType.Trigger: return require("./../../../shared/images/trigger.png");
                case NlpButtonType.Text: return require("./../../../shared/images/text.png");
                case NlpButtonType.CustomEvent: return require("./../../../shared/images/js.png");
                case NlpButtonType.Back: return require("./../../../shared/images/arrow-go-back.svg");
            }
        }
        return (
            <div className="nlp-buttons-container">
                {buttons && buttons.map(item => 
                    <div key={item.name + item.value} className="nlp-button-line">
                        <span><img onClick={() => {this._changeNlpButtonPosition(item.name, false)}} src={require("./../../../shared/images/arrow.png")} className="nlp-button-img-change-pos" title={"Вниз"}/></span>
                        <span><img onClick={() => {this._changeNlpButtonPosition(item.name, true)}} src={require("./../../../shared/images/arrow.png")} className="nlp-button-img-change-pos change-pos-up" title={"Вверх"}/></span>
                        <span className="nlp-button" title={item.value}>
                            <img src={getButtonimage(item.type)} className="nlp-button-img" title={NlpButtonType[item.type]}/>
                            <span className="nlp-button__name">{item.name}</span>
                        </span>
                        <span className="nlp-buttons-management">
                            <span onClick={() => { this.headerEditDynamicButtonText = i18n.t('nlp.buttonEditing'); this.setState({editingButton: {...item}, editingButtonName: item.name})}}>edit</span>
                            <span onClick={() => {this._onNlpButtonDelete(item)}}>delete</span>
                        </span>
                    </div>
                ) }
                <div>
                    <img src={require("./../../../shared/images/add.png")} alt="Add button" title="Add button" className="add-nlp-button" onClick={() => { this.headerEditDynamicButtonText = i18n.t('nlp.newButton'); this.setState({editingButton: createNlpButton(), editingButtonName: undefined})}}/>
                </div>
            </div>
        );
    }

    private _getTopicPairContentTypeDropdown = (): JSX.Element => {
        return (
            <Input
                type="select"
                className="topic-pair-content-type__input"
                value={topicPairContentTypeToString(this.state.editedTopicPair.contentType)}
                onChange={(event) => {this._changeTopicPairContentType(stringToTopicPairContentType(event.target.value))}} >
                    <option>{topicPairContentTypeToString(TopicPairContentType.Markup)}</option>
                    <option>{topicPairContentTypeToString(TopicPairContentType.Text)}</option>
                    <option>{topicPairContentTypeToString(TopicPairContentType.Video)}</option>
                    <option>{topicPairContentTypeToString(TopicPairContentType.Audio)}</option>
                    <option>{topicPairContentTypeToString(TopicPairContentType.Navigation)}</option>
                    <option>{topicPairContentTypeToString(TopicPairContentType.TextToSpeech)}</option>
            </Input>
        )
    }

    private _changeTopicPairContentType = (contentType: TopicPairContentType): void => {
        this.setState({
            editedTopicPair: {
                ...this.state.editedTopicPair,
                contentType: contentType
            }
        })
    }

    private _changeNlpButtonPosition = (buttonName: string, isUpDiraction: boolean) => {
        const answer = this._getEditedTopicPairAnswer();
        const index = answer.buttons.findIndex(b => b.name === buttonName);

        function arraymove(arr: INlpButton[], fromIndex: number, toIndex: number) {
            var element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
        }

        if(index > -1) {
            const {editedTopicPair} = this.state;
            if(isUpDiraction === true) {
                if(index > 0) {
                    arraymove(answer.buttons, index, index - 1)
                }
            }
            else {
                if(index < answer.buttons.length) {
                    arraymove(answer.buttons, index, index + 1)
                }
            }
            this.setState({editedTopicPair: {...editedTopicPair, answer: {...answer, buttons: [...answer.buttons]}}});
        }
    }

    private _getEditingButtonHeader = (): React.ReactElement<{}> => {
        return (
            <div>
                {this.headerEditDynamicButtonText}
            </div>
        );
    }

    private _getEditButtonType = (): string => {
        if (this.state.editingButton !== undefined) {
            this.typeEditDynamicButtonText = NlpButtonType[this.state.editingButton.type];
            return this.typeEditDynamicButtonText;
        } else {
            var type = this.typeEditDynamicButtonText ?? "";
            this.typeEditDynamicButtonText = undefined;
            return type;
        }
    }

    private _getEditingButtonBody = (): React.ReactElement<{}> => {
        return (
            <div className="edit-nlp-button-dialog-container">
                <div>
                    <label>{i18n.t('nlp.buttonType')}</label> 
                    <span>
                        <Input
                            type="select"
                            className="dialog-nlp-button-type"
                            value={this._getEditButtonType()}
                            onChange={this._onNlpButtonTypeChangeHandler}>
                            <option>{NlpButtonType[NlpButtonType.Trigger]}</option>
                            <option>{NlpButtonType[NlpButtonType.Text]}</option>
                            <option>{NlpButtonType[NlpButtonType.Link]}</option>
                            <option>{NlpButtonType[NlpButtonType.CustomEvent]}</option>
                            <option>{NlpButtonType[NlpButtonType.Back]}</option>
                        </Input>
                    </span>
                </div>
                <div><label>{i18n.t('nlp.text')}</label> <span><input type="text" value={this.state.editingButton?.name} onChange={this._onNlpButtonNameChangeHandler}/></span></div>
                <div><label>{i18n.t('nlp.value')}</label> <span><input type="text" value={this.state.editingButton?.value} onChange={this._onNlpButtonValueChangeHandler}/></span></div>
            </div>
        )
    }

    private _getEditingButtonFooter = (): React.ReactElement<{}> => {
        return (
            <div>
                <Button onClick={() => { this.setState({ editingButtonName: undefined, editingButton: undefined }) }}>{i18n.t('common.cancel')}</Button>
                <Button onClick={() => {
                    const {editedTopicPair, editingButton, editingButtonName} = this.state;

                    if(editingButton === undefined || editingButton.name.length === 0) return;
                    const answer = this._getEditedTopicPairAnswer();
                    let isDuplicate = false;
                    if(this.state.editingButtonName === undefined) {
                        if(answer.buttons?.findIndex(b => b.name === editingButton?.name) > -1) {
                            isDuplicate = true;
                        }
                    }
                    else {
                        if(editingButton.name !== editingButtonName) {
                            if(answer.buttons?.findIndex(b => b.name === editingButton?.name) > -1) {
                                isDuplicate = true;
                            }
                        }
                    }
                    
                    if (isDuplicate) {
                        alert(i18n.t('nlp.buttonTextExists'));
                        return;
                    }
                    if(answer.buttons)
                    {
                        var index = answer.buttons.findIndex(b => b.name === editingButtonName);
                        if(index > -1) {
                            answer.buttons[index] = editingButton
                        }
                        else {
                            answer.buttons = answer.buttons.concat([editingButton])
                        }
                    }
                    else {
                        answer.buttons = [editingButton];
                    }

                    this.setState({editedTopicPair: {...editedTopicPair, answer: {...answer}}, editingButtonName: undefined, editingButton: undefined})
                }}>{i18n.t('common.save')}</Button>
            </div>
        );
    }

    private _onNlpButtonTypeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const type = NlpButtonType[event.currentTarget.value as keyof typeof NlpButtonType];
        const eb = this.state.editingButton ?? createNlpButton();
        this.setState({editingButton: {...eb, type}});
    }

    private _onNlpButtonNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.currentTarget.value.length <= 400) {
            const name = event.currentTarget.value;
            const eb = this.state.editingButton ?? createNlpButton();            
            if (eb?.type === NlpButtonType.Text)
                this.setState({editingButton: {...eb, name, value: name}});
            else this.setState({editingButton: {...eb, name}});
        }
    }

    private _onNlpButtonValueChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        const eb = this.state.editingButton ?? createNlpButton();
        this.setState({editingButton: {...eb, value}});
    }

    private _getErrorControl = (): React.ReactElement<{}> => {
        return <div>{i18n.t('nlp.noFoundTopicPair')}</div>;
    };

    private _setEditedItemByTopicPair = (innerTopicPair: ITopicPair): void => {
        this.setState({
            editedTopicPair: this._buildTopicPairItem(innerTopicPair),
            currentAnswerText: innerTopicPair.answer?.text ?? "",
            answerTextVariant: 1
        });
    };

    private _checkIfHasChanges = (): boolean => {
        const {innerTopicPair} = this.props;
        const {editedTopicPair} = this.state;

        return (
            innerTopicPair.topicPairId === 0 ||
            (editedTopicPair.answer && editedTopicPair.answer.answerId === 0) ||
            (innerTopicPair.triggerName !== editedTopicPair.triggerName) ||
            (innerTopicPair.subject !== editedTopicPair.category) ||
            (innerTopicPair.answer && editedTopicPair.answer && (innerTopicPair.answer.text !== editedTopicPair.answer!.text) || 
            (innerTopicPair.answer?.alternativeText1 !== editedTopicPair.answer?.alternativeText1) ||
            (innerTopicPair.answer?.alternativeText2 !== editedTopicPair.answer?.alternativeText2)) ||
            editedTopicPair.questions.some(q => q.questionId === 0) ||
            editedTopicPair.questions.length !== innerTopicPair.questions.length ||
            innerTopicPair.questions.some(iq =>
                editedTopicPair.questions.some(q => q.questionId === iq.questionId && (q.text !== iq.text || q.isDefault !== iq.isDefault))) || 
            this._checkIfButtonsChanged(innerTopicPair.answer?.buttons, editedTopicPair.answer?.buttons) ||
            innerTopicPair.contentType !== editedTopicPair.contentType
        );
    };

    private _checkIfButtonsChanged = (innerTopicPairButtons: INlpButton[] | undefined, editedTopicPairButtons: INlpButton[] | undefined): boolean => {
        if (innerTopicPairButtons && editedTopicPairButtons) {
            const a = JSON.stringify(innerTopicPairButtons);
            const b = JSON.stringify(editedTopicPairButtons);
            return  a !== b;
        }
        return false;
    }

    private _checkIfAllQuestionFieldsAreFilled = (): boolean => {
        return this.state.editedTopicPair.questions.every(q => !!q.text);
    };

    private _buildTopicPairItem = (topicPair: ITopicPair): ITopicPairItem => {
        return {
            topicPairId: topicPair.topicPairId,
            triggerName: topicPair.triggerName,
            status: topicPair.status,
            category: topicPair.subject,
            source: topicPair.source,
            answer: {...topicPair.answer, buttons: [...topicPair.answer?.buttons ?? []]} as IAnswerItem,
            questions: topicPair.questions.map((q, index) => ({
                text: q.text,
                isDefault: q.isDefault,
                questionId: q.questionId,
                questionGuid: Guid.create().toString(),
                questionIndex: index
            })),
            contentType: topicPair.contentType
        };
    };

    private _buildTopicPair = (topicPairItem: ITopicPairItem): ITopicPair => {      
        return {
            topicPairId: topicPairItem.topicPairId,
            triggerName: topicPairItem.triggerName,
            status: topicPairItem.status,
            answer: topicPairItem.answer,
            subject: topicPairItem.category,
            source: topicPairItem.source,
            questions: topicPairItem.questions.map(q => ({
                text: q.text,
                isDefault: q.isDefault,
                questionId: q.questionId
            })),
            contentType: topicPairItem.contentType
        };
    };

    private _onSaveButtonClick = (settings: string) => {
        const answer = this._getEditedTopicPairAnswer();
        const {editedTopicPair} = this.state;

        this.setState({editedTopicPair: {...editedTopicPair, answer: {...answer, parametersJson: JSON.parse(settings)}}},
                () => this._onSaveChangesHandler());
    }
}

export default TopicPairsItem;
