import * as React from "react";
import { connect } from "react-redux";

import { IApplicationState } from "src/~store/models/ApplicationState";
import { ITopicEditorProps } from "./~types/TopicEditorProps";

import {
  getAllTopics,
  getTopicByExternalId,
  exportPairs,
  publishQuestions,
  importPairs,
  getTopicPairCategoriesByTopicName,
  setFilterTags,
  saveSynonyms,
  getSynonyms,
  generateQuestions,
  setIsPublishingQuestionsStatus
} from "../~store/actions/actions";
import {
  isTransferQuestionPopupVisibleSelector,
  isOperationInProgressSelector,
  isExportingPairsSelector,
  isPublishingQuestionsSelector,
  isMessageBoxVisibleSelector,
  selectedTopicSelector,
  topicPairCategoriesSelector,
  filterTagsSelector,
  selectedTopicPairStatusSelector,
  isGeneratingQuestionsSelector
} from "../~store/selectors";

import Loader from "src/app/shared/components/loader/Loader";
import MessageBox from "src/app/shared/components/message-boxes/message-box/MessageBox";
import TopicWidget from "./topic-widget/TopicWidget";
import TopicPairsTabs from "./topic-pairs-tabs/TopicPairsTabs";
import TopicEditorHeader from "./topic-editor-header/TopicEditorHeader";
import TransferQuestionPopup from "./transfer-question-popup/TransferQuestionPopup";
import { createMatchSelector } from "connected-react-router";
import { ITopicEditorRouteParams } from "./~types/TopicEditorRouteParams";
import { buildFileSelector } from "src/app/shared/helpers/file-helper/FileHelper";
import { ISearchingTag } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import "./TopicEditor.css";
import SynonymsDialog from "./synonyms-dialog/SynonymsDialog";
import useSaveScroll from "src/app/shared/helpers/custom-hooks/useSaveScroll/useSaveScroll";
import { useTranslation } from "react-i18next";
import { NLP_EDIT_TOPIC_PATH } from "src/app/AppConstants";

type ReduxType = ReturnType<typeof mapStateToProps> & ITopicEditorProps;

const TopicEditor = (props: ReduxType) => {
  const { t } = useTranslation();
  const [isSynonymsDialogVisible, setSynonymsDialogVisible] =
    React.useState<boolean>(false);
  const [selectedPage, setSelectedPage] = React.useState<number>(1);

  const { elementRef, resetScroll } = useSaveScroll({ trigger: !props.isOperationInProgress });

  React.useEffect(() => {
    resetScroll();
  }, [props.selectedTopicPairStatus, selectedPage]);

  React.useEffect(() => {
    return () => {
      props.setIsPublishingQuestionsStatus(false);
      props.setFilterTags([]);
    };
  }, []);

  React.useEffect(() => {
    const parts = window.location.pathname.split("/");
    props.getTopicByExternalId(parts[parts.length - 1], selectedPage);
  }, [props.filterTags, props.selectedTopicPairStatus, selectedPage]);

  React.useEffect(() => {
    if (props.selectedTopic.name.length > 0)
      props.getTopicPairCategoriesByTopicName(props.selectedTopic.name);
  }, [props.selectedTopic.name]);

  const _onPublishQuestionsHandler = (): void => {
    props.publishQuestions(props.selectedTopic.topicExternalId);
  };

  const _onExportPairsHandler = (): void => {
    props.exportPairs(props.selectedTopic.topicExternalId);
  };

  const _onImportPairsHandler = (): void => {
    const onFileToImportSelected = (e: any): void => {
      if (e.target.files.length === 0) return;
      const file = e.target.files[0] as File;
      props.importPairs(props.selectedTopic.topicExternalId, file);
    };
    buildFileSelector(
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
      onFileToImportSelected
    ).click();
  };

  const _onGenerateQuestionsHandler = (): void => {
    props.generateQuestions(props.selectedTopic.topicExternalId);
  };

  const _getTopicEditorControl = (): React.ReactElement<{}> => {
    const {
      selectedTopic,
      isMessageBoxVisible,
      isPublishingQuestions,
      isExportingPairs,
      isTransferQuestionPopupVisible,
      isGeneratingQuestions
    } = props;
    if (!selectedTopic || !selectedTopic.topicExternalId) {
      return (
        <div>
              {t("nlp.messageDontHaveAccessToTopic")}
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="topic-editor-container">
          <TopicEditorHeader
            topicId={selectedTopic.topicId}
            topicName={selectedTopic.name}
            onExportPairs={_onExportPairsHandler}
            onPublishQuestions={_onPublishQuestionsHandler}
            onImportPairs={_onImportPairsHandler}
            onSynonymsHandler={() => setSynonymsDialogVisible(true)}
            onGenerateQuestions={_onGenerateQuestionsHandler}
            isPublishingQuestions={isPublishingQuestions || (selectedTopic.isPublishing ?? false)}
            isExportingPairs={isExportingPairs || (selectedTopic.isImproting ?? false)}
            isGeneratingQuestions={isGeneratingQuestions}
            topicPairCategories={props.topicPairCategories}
            filterTags={props.filterTags}
          />
          <TopicPairsTabs
            topicPairCategories={props.topicPairCategories}
            topicExternalId={selectedTopic.topicExternalId}
            topicPairsCount={selectedTopic.topicPairsCount}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            topicPairsRef={elementRef}
          />
          <TopicWidget />
          {isTransferQuestionPopupVisible && <TransferQuestionPopup />}
          {isMessageBoxVisible && <MessageBox />}
          {isSynonymsDialogVisible && (
            <SynonymsDialog
              onClose={() => setSynonymsDialogVisible(false)}
              saveSynonyms={(synonymstext, onSuccess) =>
                props.saveSynonyms(
                  props.selectedTopic.topicExternalId,
                  synonymstext,
                  onSuccess
                )
              }
              getSynonyms={(onSuccess) =>
                props.getSynonyms(
                  props.selectedTopic.topicExternalId,
                  onSuccess
                )
              }
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  return _getTopicEditorControl();
};

const mapStateToProps = (
  state: IApplicationState,
  props: ITopicEditorProps
) => {
  return {
    selectedTopic: selectedTopicSelector(state),
    isExportingPairs: isExportingPairsSelector(state),
    isPublishingQuestions: isPublishingQuestionsSelector(state),
    isOperationInProgress: isOperationInProgressSelector(state),
    isTransferQuestionPopupVisible:
      isTransferQuestionPopupVisibleSelector(state),
    isMessageBoxVisible: isMessageBoxVisibleSelector(state),
    topicPairCategories: topicPairCategoriesSelector(state),
    filterTags: filterTagsSelector(state),
    selectedTopicPairStatus: selectedTopicPairStatusSelector(state),
    matchParams: createMatchSelector(`${NLP_EDIT_TOPIC_PATH}/:topicExternalId`)(state)
      ?.params as ITopicEditorRouteParams,
    isGeneratingQuestions: isGeneratingQuestionsSelector(state)
  };
};

const mapDispatchToProps = (dispatch: any): ITopicEditorProps => {
  return {
    getAllTopics: () => dispatch(getAllTopics),
    getTopicByExternalId: (topicExternalId: string, selectedPage: number = 1) =>
      dispatch(getTopicByExternalId(topicExternalId, selectedPage)),
    exportPairs: (topicExternalId: string) =>
      dispatch(exportPairs(topicExternalId)),
    publishQuestions: (topicExternalId: string) =>
      dispatch(publishQuestions(topicExternalId)),
    importPairs: (topicExternalId: string, file: File) =>
      dispatch(importPairs(topicExternalId, file)),
    setFilterTags: (tags: ISearchingTag[]) => dispatch(setFilterTags(tags)),
    getTopicPairCategoriesByTopicName: (topicName: string) =>
      dispatch(getTopicPairCategoriesByTopicName(topicName)),
    saveSynonyms: (
      topicExternalId: string,
      synonymsText: string,
      onSuccess: () => void
    ) => saveSynonyms(topicExternalId, synonymsText, onSuccess),
    getSynonyms: (
      topicExternalId: string,
      onSuccess: (synonymsText: string) => void
    ) => getSynonyms(topicExternalId, onSuccess),
    generateQuestions:(topicExternalId: string) =>
    dispatch(generateQuestions(topicExternalId)),
    setIsPublishingQuestionsStatus: (status: boolean) => dispatch(setIsPublishingQuestionsStatus(status))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicEditor);
