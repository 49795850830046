import i18n from "src/app/shared/localization/i18n";

export enum ChannelType {
    Telegram = 1,
    Viber = 2,
    Vk = 3,
    Widget = 4,
    Facebook = 5,
    WeChat = 6,
    Instagram = 7,
    UniformWidget = 8,
    EdnaWhatsApp = 9,
    WhatsApp = 10,
    VoiceChannel = 11,
    DevinoWhatsApp = 12
}

export const channelTypeToString = (channelType: ChannelType): string => {
    switch(channelType) {
        case ChannelType.Telegram:
            return i18n.t('channels.telegram');
        case ChannelType.Viber:
            return i18n.t('channels.viber');
        case ChannelType.Vk:
            return i18n.t('channels.vk');
        case ChannelType.Widget:
            return i18n.t('channels.widget');
        case ChannelType.Facebook:
            return i18n.t('channels.facebook');
        case ChannelType.WeChat:
            return i18n.t('channels.weChat');
        case ChannelType.Instagram:
            return i18n.t('channels.instagram');
        case ChannelType.UniformWidget:
            return i18n.t('channels.uniformWidget');
        case ChannelType.EdnaWhatsApp:
            return i18n.t('channels.ednaWhatsApp');
        case ChannelType.WhatsApp:
            return i18n.t('channels.whatsApp');
        case ChannelType.VoiceChannel:
            return i18n.t('channels.voiceChannel');
        case ChannelType.DevinoWhatsApp:
            return i18n.t('channels.devinoWhatsApp');
    }
}